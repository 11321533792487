import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DataMessageType } from '../types/MeetingMessage';
import { useJoinMeeting } from './JoinMeetingProvider';
import { useRights } from './RightsProvider';

type Props = {
  children: ReactNode;
};

interface StartMeetingValue {
  meetingStarted: boolean;
}

const StartMeetingContext = React.createContext<StartMeetingValue>({
  meetingStarted: false,
});

export function StartMeetingProvider({ children }: Props) {
  const meetingManager = useMeetingManager();
  const { isLoading } = useJoinMeeting();
  const { mayStartMeeting } = useRights();
  const [meetingStarted, setMeetingStarted] = useState<boolean>(false);

  const startMeeting = async () => {
    await meetingManager.start();
    meetingManager.audioVideo?.realtimeMuteLocalAudio();
    setMeetingStarted(true);
    if (mayStartMeeting) {
      const messageData: DataMessageType = {
        eventName: 'meetingStatus',
        eventStatus: 'started',
        attendeeId: '',
      };
      meetingManager.audioVideo?.realtimeSendDataMessage(
        'meetingStatus',
        messageData,
        300000
      );
    }
  };

  useEffect(() => {
    if (!isLoading) {
      startMeeting();
    }
  }, [isLoading, meetingManager]);

  return (
    <StartMeetingContext.Provider value={{ meetingStarted: meetingStarted }}>
      {children}
    </StartMeetingContext.Provider>
  );
}

export function useStartMeeting(): StartMeetingValue {
  const state = useContext(StartMeetingContext);

  if (!state) {
    throw new Error('useStartMeeting must be used within StartMeetingProvider');
  }

  return state;
}
