import styled from 'styled-components';
import { ellipsis } from '../../../utils/style';
import { baseSpacing, baseStyles } from '../../Base';

export const StyledChatHeader = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 0.0625rem solid ${(props) => props.theme.roster.headerBorder};

  .ch-title {
    font-size: 0.875rem;
    color: ${(props) => props.theme.roster.primaryText};
    ${(props) => (props.isSearching ? 'opacity: 0;' : '')}
    ${ellipsis};
  }

  .ch-badge {
    margin-left: 0.5rem;
    ${(props) => (props.isSearching ? 'opacity: 0;' : '')}
  }

  .ch-buttons {
    margin-left: auto;
    display: flex;

    > * {
      margin-left: 0.5rem;
    }

    ${(props) => (props.isSearching ? 'opacity: 0;' : '')}
  }

  ${baseSpacing}
  ${baseStyles}
`;
