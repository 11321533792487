import {
  Camera,
  Microphone,
  ScreenShare,
} from 'amazon-chime-sdk-component-library-react';
import React, { FC } from 'react';
import styled from 'styled-components';
import IconButton from '../../../components/Button/IconButton';
import useTranslate from '../../../hooks/useTranslate';
import { useLocalAttendee } from '../../../providers/LocalAttendeeProvider';
import { useRightsManagement } from '../../../providers/MeetingMessagesProvider/RightsManagementProvider';
import { UserRights } from '../../../types/UserRights';

const RequestPermissionsMenu: FC<RequestPermissionsMenuProps> = () => {
  const translate = useTranslate();
  const { localAttendee } = useLocalAttendee();
  const { requestAttendeeRights } = useRightsManagement();

  const handleRequestRights = (requested: UserRights[]) => {
    requestAttendeeRights(requested);
  };

  const requestedRights = localAttendee.requestedRights;
  const requestedAudio = requestedRights.has(UserRights.ALLOW_ACTIVE_AUDIO);
  const requestedVideo = requestedRights.has(UserRights.ALLOW_ACTIVE_CAMERA);
  const requestedShare = requestedRights.has(UserRights.ALLOW_ACTIVE_SHARE);
  const requestedAll = requestedAudio && requestedVideo && requestedShare;

  return (
    <StyledMenuItem>
      <StyledMenuLabel>{translate('RequestRightsMenu.Title')}</StyledMenuLabel>
      <StyledIconButton
        active={requestedAudio}
        icon={<Microphone />}
        onClick={() => handleRequestRights([UserRights.ALLOW_ACTIVE_AUDIO])}
      />
      <StyledIconButton
        active={requestedVideo}
        icon={<Camera />}
        onClick={() => handleRequestRights([UserRights.ALLOW_ACTIVE_CAMERA])}
      />
      <StyledIconButton
        active={requestedShare}
        icon={<ScreenShare />}
        onClick={() => handleRequestRights([UserRights.ALLOW_ACTIVE_SHARE])}
      />
      <StyledTextButton
        active={requestedAll}
        onClick={() =>
          handleRequestRights([
            UserRights.ALLOW_ACTIVE_CAMERA,
            UserRights.ALLOW_ACTIVE_AUDIO,
            UserRights.ALLOW_ACTIVE_SHARE,
          ])
        }
      >
        {translate('RequestRightsMenu.RequestAllRights')}
      </StyledTextButton>
    </StyledMenuItem>
  );
};

type RequestPermissionsMenuProps = {};

export default RequestPermissionsMenu;

const StyledMenuItem = styled.li`
  display: flex;
  place-content: center;
`;

const StyledMenuLabel = styled.div`
  color: ${(props) => props.theme.colors.greys.white};
  height: 1rem;
  margin: auto 1rem;
`;

const StyledIconButton = styled(IconButton)<{ active?: boolean }>`
  .ch-icon {
    color: ${(props) => props.active && props.theme.colors.primary.lightest};
  }
`;

const StyledTextButton = styled.div<{ active?: boolean }>`
  color: ${(props) =>
    props.active
      ? props.theme.colors.primary.lightest
      : props.theme.colors.greys.white};
  cursor: pointer;
  height: 1rem;
  margin: auto 1rem auto 0;
`;
