import React, { useEffect } from 'react';
import Placeholder from '../../components/Placeholders';
import VideoTileGrid from '../../components/Videos/VideoTileGrid';
import OneOnOneControls from '../../containers/MeetingControls/OneOnOneControls';
import MeetingDetails from '../../containers/MeetingDetails';
import MeetingHeader from '../../containers/MeetingHeader/MeetingHeader';
import NavigationControl from '../../containers/NavigationControls/NavigationControl';
import { useHeartbeat } from '../../providers/HeartbeatProvider';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import { LocalAttendeeProvider } from '../../providers/LocalAttendeeProvider';
import { MaxAttendeesProvider } from '../../providers/MaxAttendeesProvider';
import { AudioVideoAttendeesProvider } from '../../providers/MeetingMessagesProvider/AudioVideoAttendeeProvider';
import { HandRaiseProvider } from '../../providers/MeetingMessagesProvider/HandRaiseProvider';
import { MeetingStatusProvider } from '../../providers/MeetingMessagesProvider/MeetingStatusProvider';
import { PromotedAttendeesProvider } from '../../providers/MeetingMessagesProvider/PromotedAttendeesProvider';
import RightsManagementProvider from '../../providers/MeetingMessagesProvider/RightsManagementProvider';
import { useNavigation } from '../../providers/NavigationProvider';
import { RosterProvider } from '../../providers/RosterProvider';
import { VideoGridLayoutProvider } from '../../providers/VideoGridLayoutProvider';
import { StyledContent, StyledHeader, StyledLayout } from './Styled';

const MeetingView = () => {
  const { showRoster, showChat, showSettings } = useNavigation();
  const { joinInfo, isOneOnOneMeeting } = useJoinMeeting();
  const usePlaceholder = joinInfo?.meeting.useplaceholder;

  const { switchHeartbeatStatus } = useHeartbeat();
  useEffect(() => {
    switchHeartbeatStatus('joined');
  }, [switchHeartbeatStatus]);

  return (
    <RosterProvider>
      <MeetingStatusProvider>
        <MaxAttendeesProvider>
          <VideoGridLayoutProvider>
            <LocalAttendeeProvider>
              <RightsManagementProvider>
                <PromotedAttendeesProvider>
                  <HandRaiseProvider>
                    <AudioVideoAttendeesProvider>
                      <StyledLayout
                        showRoster={showRoster}
                        showChat={showChat}
                        showSettings={showSettings}
                        isOneOnOneMeeting={isOneOnOneMeeting}
                      >
                        <StyledHeader>
                          <MeetingHeader
                            className={'header'}
                            noControls={isOneOnOneMeeting}
                          />
                        </StyledHeader>
                        <StyledContent>
                          <VideoTileGrid
                            className="videos"
                            noRemoteVideoView={<MeetingDetails />}
                          />
                          {usePlaceholder && <Placeholder />}
                        </StyledContent>
                        {isOneOnOneMeeting && <OneOnOneControls />}
                        <NavigationControl />
                      </StyledLayout>
                    </AudioVideoAttendeesProvider>
                  </HandRaiseProvider>
                </PromotedAttendeesProvider>
              </RightsManagementProvider>
            </LocalAttendeeProvider>
          </VideoGridLayoutProvider>
        </MaxAttendeesProvider>
      </MeetingStatusProvider>
    </RosterProvider>
  );
};

export default MeetingView;
