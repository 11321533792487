import {
  useBackgroundBlur,
  useLogger,
  useMeetingManager,
  useVideoInputs,
} from 'amazon-chime-sdk-component-library-react';
import {
  BackgroundReplacementVideoFrameProcessor,
  DefaultVideoTransformDevice,
  isVideoTransformDevice,
  VideoInputDevice,
} from 'amazon-chime-sdk-js';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import useTranslate from '../hooks/useTranslate';
import { loadBackgroundImages } from '../utils/api';
import { useJoinMeeting } from './JoinMeetingProvider';
import none from '../nobackground.jpg';
import blur from '../blur.jpg';

export enum BackgroundType {
  None,
  Blur,
  Image,
}

export interface VideoBackground {
  type: BackgroundType;
  value: string;
  label: string;
  blob?: Blob;
  src: string;
}

interface VideoBackgroundContext {
  backgroundOptions: VideoBackground[];
  activeVideoBackground: VideoBackground;
  chooseActiveVideoBackground: (videoBackground: VideoBackground) => void;
}

type Props = {
  children: ReactNode;
};

const context = React.createContext<VideoBackgroundContext | null>(null);

export function getVideoBackgroundContext() {
  return context;
}

export default function VideoBackgroundProvider({ children }: Props) {
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();
  const logger = useLogger();
  const translate = useTranslate();
  const { joinInfo } = useJoinMeeting();

  const [activeVideoBackground, setActiveVideoBackground] =
    useState<VideoBackground>();
  const VideoBackgroundContext = getVideoBackgroundContext();
  const { isBackgroundBlurSupported, createBackgroundBlurDevice } =
    useBackgroundBlur();
  const [currentVideoInputDevice, setCurrentVideoInputDevice] =
    useState<VideoInputDevice>(selectedDevice);

  const [isLoading, setIsLoading] = useState(false);
  const [backgroundOptions, setBackgroundOptions] = useState<VideoBackground[]>(
    [
      {
        type: BackgroundType.None,
        value: 'none',
        label: translate('VideoBackgrounds.Options.NoneLabel'),
        src: none,
      },
      {
        type: BackgroundType.Blur,
        value: 'blur',
        label: translate('VideoBackgrounds.Options.BlurLabel'),
        src: blur,
      },
    ]
  );

  useEffect(() => {
    if (!isVideoTransformDevice(selectedDevice)) {
      const selectedBackground = backgroundOptions.find(
        (videoBackgroundOption) => {
          return videoBackgroundOption.value === 'none';
        }
      );
      setActiveVideoBackground(selectedBackground);
    }
  }, [selectedDevice, backgroundOptions]);

  useEffect(() => {
    if (!joinInfo) {
      return;
    }
    loadImages();
  }, [joinInfo]);

  const loadImages = async () => {
    const promises = joinInfo.meeting.backgroundImages?.map(
      async (url: string, index: number) => {
        const response = await loadBackgroundImages(url);
        backgroundOptions.push({
          type: BackgroundType.Image,
          value: `image-${index}`,
          label: `${translate('VideoBackgrounds.Options.ImageLabel')} ${
            index + 1
          }`,
          blob: response.data,
          src: URL.createObjectURL(response.data),
        });
        setBackgroundOptions(backgroundOptions);
      }
    );

    Promise.all(promises);
  };

  const chooseActiveVideoBackground = async (
    videoBackground: VideoBackground
  ): Promise<void> => {
    if (isLoading || !selectedDevice) {
      return;
    }
    let current: VideoInputDevice;

    setIsLoading(true);

    try {
      if (
        videoBackground.type === BackgroundType.None &&
        isVideoTransformDevice(selectedDevice)
      ) {
        current = await selectedDevice.intrinsicDevice();
        setCurrentVideoInputDevice(current);
      } else if (
        videoBackground.type === BackgroundType.Blur &&
        !isVideoTransformDevice(selectedDevice)
      ) {
        if (!isBackgroundBlurSupported) {
          logger.warn('Background blur processor is not supported yet.');
          return;
        }
        current = await createBackgroundBlurDevice(selectedDevice);
        setCurrentVideoInputDevice(current);
      } else if (
        videoBackground.type === BackgroundType.Blur &&
        isVideoTransformDevice(currentVideoInputDevice)
      ) {
        await currentVideoInputDevice.stop();
        current = await createBackgroundBlurDevice(
          await currentVideoInputDevice.intrinsicDevice()
        );
        setCurrentVideoInputDevice(current);
      } else if (
        videoBackground.type === BackgroundType.Image &&
        !isVideoTransformDevice(selectedDevice)
      ) {
        const processors = [];
        const options = { imageBlob: videoBackground.blob };
        const replacementProcessor =
          await BackgroundReplacementVideoFrameProcessor.create(null, options);
        processors.push(replacementProcessor);
        current = new DefaultVideoTransformDevice(
          logger,
          selectedDevice,
          processors
        );
        setCurrentVideoInputDevice(current);
      } else if (
        videoBackground.type === BackgroundType.Image &&
        isVideoTransformDevice(currentVideoInputDevice)
      ) {
        await currentVideoInputDevice.stop();

        const processors = [];
        const options = { imageBlob: videoBackground.blob };
        const replacementProcessor =
          await BackgroundReplacementVideoFrameProcessor.create(null, options);
        processors.push(replacementProcessor);
        current = new DefaultVideoTransformDevice(
          logger,
          await currentVideoInputDevice.intrinsicDevice(),
          processors
        );
        setCurrentVideoInputDevice(current);
      }
      if (current) {
        await meetingManager.startVideoInputDevice(current);
      }
    } catch (error) {
      setIsLoading(false);
      logger.error('Failed to change background');
    } finally {
      setIsLoading(false);
    }

    setActiveVideoBackground(videoBackground);
    setIsLoading(false);
  };

  const providerValue = {
    backgroundOptions,
    activeVideoBackground,
    chooseActiveVideoBackground,
  };
  return (
    <VideoBackgroundContext.Provider value={providerValue}>
      {children}
    </VideoBackgroundContext.Provider>
  );
}

export const useVideoBackground = () => {
  const videoBackground = useContext(context);
  if (!videoBackground) {
    console.log(
      'useVideoBackground must be used within VideoGridLayoutProvider'
    );
  }

  return videoBackground;
};
