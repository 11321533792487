import { RosterAttendeeType } from '../types/RosterAttendeeType';

export const sortUserList = (
  data: RosterAttendeeType[],
  raiseHandAttendeeIds: string[]
) => {
  if (Array.isArray(data)) {
    data.sort((a, b) => {
      return compareUsers(a, b, raiseHandAttendeeIds);
    });
  }
  return data;
};

export type ComparableUser = {
  fullname: string;
  lastname: string;
  requestedRights: boolean;
  raisedHand: boolean;
  raisedHandTimestamp: number;
  joinTimestamp: number;
};

export const compareRaisedHandsTiming = (
  userA: ComparableUser,
  userB: ComparableUser
) => {
  if (userA.raisedHandTimestamp < userB.raisedHandTimestamp) return -1;
  else if (userA.raisedHandTimestamp > userB.raisedHandTimestamp) return 1;

  return 0;
};

export const compareRaisedHands = (
  userA: ComparableUser,
  userB: ComparableUser
) => {
  if (userA.raisedHand && userB.raisedHand) {
    return compareRaisedHandsTiming(userA, userB);
  }

  if (userA.raisedHand) return -1;

  if (userB.raisedHand) return 1;

  return 0;
};

export const compareRequestedRights = (
  userA: ComparableUser,
  userB: ComparableUser
) => {
  if (userA.requestedRights) return -1;

  if (userB.requestedRights) return 1;

  return 0;
};

const compareLastNames = (userA: ComparableUser, userB: ComparableUser) => {
  if (userA.lastname < userB.lastname) return -1;

  if (userA.lastname > userB.lastname) return 1;

  return 0;
};

const compareFullNames = (userA: ComparableUser, userB: ComparableUser) => {
  if (userA.fullname < userB.fullname) return -1;

  if (userA.fullname > userB.fullname) return 1;

  return 0;
};

export const compareNames = (userA: ComparableUser, userB: ComparableUser) => {
  return compareLastNames(userA, userB) || compareFullNames(userA, userB);
};

export const compareLoginTimes = (
  userA: ComparableUser,
  userB: ComparableUser
) => {
  if (userA.joinTimestamp < userB.joinTimestamp) return -1;

  if (userA.joinTimestamp > userB.joinTimestamp) return 1;

  return 0;
};

const toComparableUser = (
  attendee: RosterAttendeeType,
  raiseHandAttendeeIds: string[]
): ComparableUser => {
  const user = {
    fullname: attendee.fullname,
    lastname: attendee.lastname,
    requestedRights: attendee.requestedRights.size > 0,
    raisedHand: raiseHandAttendeeIds.some((id) => id === attendee.id),
    raisedHandTimestamp: 0,
    joinTimestamp: attendee.joinon,
  };

  return user;
};

const compareUsers = (a: RosterAttendeeType, b: RosterAttendeeType, raiseHandAttendeeIds: string[]) => {
  const userA = toComparableUser(a, raiseHandAttendeeIds);
  const userB = toComparableUser(b, raiseHandAttendeeIds);
  return  compareRequestedRights(userA, userB) || compareRaisedHands(userA, userB) || compareNames(userA, userB) || compareLoginTimes(userA, userB);
};
