import {
  ActionType,
  MeetingManager,
  Severity,
  useMeetingManager,
  useNotificationDispatch,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import useTranslate from '../hooks/useTranslate';
import { JoinMeetingResponse } from '../types/JoinMeetingResponse';
import { joinChimeMeetingBatch, joinChimeMeetingMod } from '../utils/api';
import { getErrorContext } from './ErrorProvider';
import { useEventContext } from './EventProvider';
import { useRights } from './RightsProvider';

type Props = {
  children: ReactNode;
};

interface JoinMeetingValue {
  joinInfo: JoinMeetingResponse | null;
  isOneOnOneMeeting: boolean | null;
  isLoading: boolean;
}

const JoinMeetingContext = React.createContext<JoinMeetingValue | null>(null);

export function useJoinMeeting(): JoinMeetingValue {
  const state = useContext(JoinMeetingContext);

  if (!state) {
    throw new Error('useJoinMeeting must be used within JoinMeetingProvider');
  }

  return state;
}

export function JoinMeetingProvider({ children }: Props) {
  const event = useEventContext();
  const meetingManager = useMeetingManager();
  const { updateErrorMessage } = useContext(getErrorContext());
  const { loginUser, mayStartMeeting } = useRights();
  const dispatch = useNotificationDispatch();
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState(true);
  const [joinInfo, setJoinInfo] = useState<JoinMeetingResponse | null>(null);
  const [isOneOnOneMeeting, setIsOneOnOneMeeting] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const joinMeetingMod = async () => {
      setIsLoading(true);
      try {
        const response = await joinChimeMeetingMod(
          event.brandId,
          event.meetingId as string,
          event.userId
        );

        if (response.data.code !== 200) {
          throw new Error(response.data.data.message || 'Server Error');
        }

        const responseBody = response.data.data;

        if (!responseBody) {
          console.log(response);
        }

        setJoinInfo(responseBody);

        setIsOneOnOneMeeting(responseBody.meeting.type === '1on1');

        await joinMeetingWithConfig(responseBody, meetingManager);

        setIsLoading(false);
      } catch (error) {
        updateErrorMessage(`${error}`);
        setIsLoading(false);
      }
    };

    const joinMeetingBatch = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await joinChimeMeetingBatch(
          event.brandId,
          event.meetingId as string,
          event.userId
        );

        if (response.status === 502) {
          throw new Error(`Server Error - ${502}`);
        }
        if (response.data.code === 400) {
          const errorCode = response.data.data.error[0][0].number;
          throw new Error(errorCode || 'Server Error');
        }

        const responseBody = response.data.data;

        if (
          responseBody.statusCode === 2098 ||
          responseBody.statusCode === 2099
        ) {
          throw new Error(responseBody.statusCode);
        }

        if (responseBody.statusCode === 2004) {
          dispatch({
            type: ActionType.ADD,
            payload: {
              severity: Severity.INFO,
              message: translate('JoinMeetings.NotStarted'),
              autoClose: true,
              replaceAll: true,
            },
          });
          setTimeout(() => {
            joinMeetingBatch();
          }, 5000);
        }

        if (
          responseBody.statusCode === 2001 ||
          responseBody.statusCode === 2002 ||
          responseBody.statusCode === 2003
        ) {
          setTimeout(() => {
            joinMeetingBatch();
          }, 1000);
        }

        if (responseBody.statusCode === 2000) {
          setJoinInfo(responseBody);
          setIsOneOnOneMeeting(responseBody.meeting.type === '1on1');

          await joinMeetingWithConfig(responseBody, meetingManager);

          setIsLoading(false);
        }
      } catch (error) {
        updateErrorMessage(`${error}`);
        setIsLoading(false);
      }
    };

    if (mayStartMeeting) {
      joinMeetingMod();
    }
    if (!mayStartMeeting && loginUser !== null) {
      joinMeetingBatch();
    }
  }, [event, meetingManager, updateErrorMessage, loginUser]);

  const providerValue = {
    joinInfo,
    isOneOnOneMeeting,
    isLoading,
  };

  return (
    <JoinMeetingContext.Provider value={providerValue}>
      {children}
    </JoinMeetingContext.Provider>
  );
}
const joinMeetingWithConfig = async (
  responseBody: any,
  meetingManager: MeetingManager
) => {
  const meetingInfo = {
    MeetingId: responseBody.meeting.chimemeetingid,
    MediaPlacement: {
      AudioHostUrl: responseBody.meeting.audiohosturl,
      ScreenDataUrl: responseBody.meeting.screendataurl,
      ScreenSharingUrl: responseBody.meeting.screensharingurl,
      ScreenViewingUrl: responseBody.meeting.screenviewingurl,
      SignalingUrl: responseBody.meeting.signalingurl,
      TurnControlUrl: responseBody.meeting.turncontrolurl,
    },
  };
  const attendeeInfo = {
    ExternalUserId: responseBody.user.userid,
    AttendeeId: responseBody.user.id,
    JoinToken: responseBody.user.jointoken,
  };
  const meetingSessionConfiguration = new MeetingSessionConfiguration(
    meetingInfo,
    attendeeInfo
  );

  await meetingManager.join(meetingSessionConfiguration);
};
