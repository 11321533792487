import styled from 'styled-components';
import { ellipsis } from '../../../utils/style';
import { baseSpacing, baseStyles } from '../../Base';

export const StyledNamedContentShare = styled.div`
  position: relative;
  grid-area: ft;
  
  .ch-nameplate {
    backdrop-filter: blur(20px);
    background-color: rgba(46, 47, 52, 0.85);
    border-radius: 0.25rem;
    bottom: 0.5rem;
    color: ${(props) => props.theme.colors.greys.white};
    left: 0.5rem;
    max-width: calc(100% - 2rem);
    padding: 0.5rem;
    position: absolute;
    z-index: 10000;

    div {
      ${ellipsis};
      display: flex;
      align-items: center;
    }

    .ch-text {
      font-size: ${(props) => props.theme.fontSizes.text.fontSize};
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
  ${baseSpacing}
  ${baseStyles}
`;
