import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BackendEvents from '../../enums/BackendEvents';
import useAttendeeEvents from '../../hooks/useAttendeeEvents';
import { DataMessageType } from '../../types/MeetingMessage';

interface ContextState {
  promotedAttendeeIds: string[];
  askPromotion: (
    attendeeId: string,
    eventStatus: string,
    userId: string
  ) => void;
  showPromotionModal: boolean;
  openPromotionModal: () => void;
  closePromotionModal: () => void;
}

const Context = createContext<ContextState | null>(null);

const PromotedAttendeesProvider: React.FC = ({ children }) => {
  const { toggleAttendeeEvent } = useAttendeeEvents();
  const audioVideo = useAudioVideo();

  const [promotedAttendeeIds, setPromotedAttendeeId] = useState<string[]>([]);
  const [showPromotionModal, setShowPromotionModal] = useState<boolean>(false);

  const askPromotion = useCallback(
    (attendeeId: string, eventStatus: string, userId: string) => {
      if (eventStatus === 'on') {
        setPromotedAttendeeId((oldIds) => [...oldIds, attendeeId]);
        toggleAttendeeEvent(attendeeId, BackendEvents.Promote, true).then();
      }
      if (eventStatus === 'off') {
        setPromotedAttendeeId((oldIds) =>
          oldIds.filter((id: string) => id !== attendeeId)
        );
        toggleAttendeeEvent(BackendEvents.Promote, attendeeId).then();
      }
    },
    [toggleAttendeeEvent]
  );

  const openPromotionModal = useCallback(() => {
    setShowPromotionModal(true);
  }, []);

  const closePromotionModal = useCallback(() => {
    setShowPromotionModal(false);
  }, []);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const messageCallback = async (data: DataMessage) => {
      if (data.topic !== BackendEvents.Promote) {
        return;
      }
      const promoteEvent: DataMessageType = data.json();

      if (promoteEvent.eventStatus === 'on') {
        setPromotedAttendeeId((oldIds) => [...oldIds, promoteEvent.attendeeId]);
      }
      if (promoteEvent.eventStatus === 'off') {
        setPromotedAttendeeId((oldIds) =>
          oldIds.filter((id: string) => id !== promoteEvent.attendeeId)
        );
      }
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      BackendEvents.Promote,
      messageCallback
    );

    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
        BackendEvents.Promote
      );
    };
  }, [audioVideo]);

  const value = useMemo(
    () => ({
      promotedAttendeeIds,
      showPromotionModal,
      askPromotion,
      openPromotionModal,
      closePromotionModal,
    }),
    [
      promotedAttendeeIds,
      showPromotionModal,
      askPromotion,
      openPromotionModal,
      closePromotionModal,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function usePromotedAttendees(): ContextState {
  const state = useContext(Context);

  if (!state) {
    throw new Error(
      'usePromotedAttendees must be used within PromotedAttendeesProvider'
    );
  }

  return state;
}

export { PromotedAttendeesProvider, usePromotedAttendees };
