import { HandRaise } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';
import BackendEvents from '../../enums/BackendEvents';
import useAttendeeEvents from '../../hooks/useAttendeeEvents';
import { useLocalAttendee } from '../../providers/LocalAttendeeProvider';
import { useHandRaise } from '../../providers/MeetingMessagesProvider/HandRaiseProvider';

interface Props {
  label?: string;
  pauseLabel?: string;
  unpauseLabel?: string;
}

const RaiseHandControl: React.FC<Props> = ({ label = 'Content' }) => {
  const { toggleRaiseHand, raiseHandAttendeeIds } = useHandRaise();
  const { toggleAttendeeEvent } = useAttendeeEvents();
  const { localAttendee } = useLocalAttendee();
  const chimeAttendeeId = localAttendee.attendeeid;

  const hasRaisedHand = raiseHandAttendeeIds.includes(chimeAttendeeId);
  const statusParam = !hasRaisedHand ? 'on' : 'off';

  return (
    <>
      <ControlBarButton
        className={hasRaisedHand ? 'inactive' : undefined}
        title={label}
        icon={<HandRaise />}
        onClick={() => {
          toggleRaiseHand(chimeAttendeeId, statusParam);
          toggleAttendeeEvent(
            chimeAttendeeId,
            BackendEvents.RaiseHand,
            !hasRaisedHand
          ).then();
        }}
      />
    </>
  );
};

export default RaiseHandControl;
