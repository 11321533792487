import {
  ContentShare,
  useContentShareState,
} from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import React, { useEffect, useState } from 'react';
import { useRosterState } from '../../../providers/RosterProvider';
import { StyledNamedContentShare } from './Styled';

interface Props extends BaseSdkProps {
  nameplate?: string | null;
}

export const NamedContentShare: React.FC<Props> = ({
  nameplate,
  className,
  ...rest
}) => {
  const { sharingAttendeeId, tileId } = useContentShareState();
  const { roster } = useRosterState();
  const [name, setName] = useState<string>();

  useEffect(() => {
    if (!sharingAttendeeId) {
      return;
    }
    const attendeeId = sharingAttendeeId?.substring(
      0,
      sharingAttendeeId.length - 8
    ); // AttendeeId hat bei ContentShare noch #content am Ende stehen, die hier abgeschnitten werden.

    const attendee = roster[attendeeId];
    const  fullname = attendee?.fullname || '';
    setName(fullname);
  }, [roster, sharingAttendeeId]);

  return tileId ? (
    <StyledNamedContentShare>
      <ContentShare
        css="grid-area: ft;"
        className={className || ''}
        {...rest}
      />
      <header className="ch-nameplate">
        <p className="ch-text">{name}</p>
      </header>
    </StyledNamedContentShare>
  ) : null;
};

export default NamedContentShare;
