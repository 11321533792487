import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { DataMessageType } from '../types/MeetingMessage';
import { setEvent } from '../utils/api';

const useAttendeeEvents = () => {
  const audioVideo = useAudioVideo();


  const toggleAttendeeEvent = async (
    chimeAttendeeId: string,
    event: string,
    status?: boolean
  ): Promise<void> => {
    // Frontend Realtime Logic
    const eventStatus = status ? 'on' : 'off';
    const messageData: DataMessageType = {
      eventName: event,
      eventStatus: eventStatus,
      attendeeId: chimeAttendeeId,
    };
    audioVideo?.realtimeSendDataMessage(event, messageData, 300000);

    // Save state in backend
    const statusPathParam = status ? 'on' : 'off';
    await setEvent(chimeAttendeeId, event, statusPathParam)
  };

  return {
    toggleAttendeeEvent,
  };
};

export default useAttendeeEvents;
