import styled from 'styled-components';
import { ellipsis } from '../../utils/style';

export const StyledContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  width: 100%
  overflow-x: auto;
`;

export const StyledPlaceholder = styled.div`
  width: 7.2rem;
  height: 4.05rem;
  border: 0.5px solid ${(props) => props.theme.colors.greys.grey70};
  border-radius: 0.25rem;
  position: relative;
  margin: 2.5px;
  background-color: ${(props) => props.theme.colors.greys.grey10};

  .icon-container {
    display: flex;
    justify-content: center;
  }

  .icon {
    height: 2rem;
    width: 2rem;
  }


  .ch-nameplate {
    backdrop-filter: blur(20px);
    background-color: rgba(46, 47, 52, 0.85);
    bottom: 0;
    color: ${(props) => props.theme.colors.greys.white};
    left: 0;
    width: 100%;
    padding: 0.5rem;
    position: absolute;

    div {
      ${ellipsis};
      display: flex;
      align-items: center;
    }

    .ch-text {
      font-size: 0.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

`;
