import { useSelectVideoQuality } from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import CameraDevices from './CameraDevices';
import MicrophoneDevices from './MicrophoneDevices';
import SpeakerDevices from './SpeakerDevices';
import { StyledAudioGroup, StyledVideoGroup, StyledWrapper } from './Styled';

const DeviceSelection = () => {
  const selectVideoQuality = useSelectVideoQuality();

  useEffect(() => {
    selectVideoQuality('360p');
  }, [selectVideoQuality]);

  return (
    <StyledWrapper>
      <StyledAudioGroup>
        <MicrophoneDevices />
        <SpeakerDevices />
      </StyledAudioGroup>
      <StyledVideoGroup>
        <CameraDevices />
      </StyledVideoGroup>
    </StyledWrapper>
  );
};

export default DeviceSelection;
