import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import AudioInputControl from './AudioInputControl';
import ContentShareControl from './ContentShareControl';
import RaiseHandControl from './RaiseHandControl';
import { StyledControlBar, StyledControls } from './Styled';
import VideoInputControl from './VideoInputControl';

const MeetingControls = () => {
  const translate = useTranslate();

  return (
    <StyledControls className="controls">
      <StyledControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels={false}
      >
        <AudioInputControl />
        <VideoInputControl label={translate('MeetingControls.VideoLabel')} />
        <ContentShareControl
          label={translate('MeetingControls.ContentLabel')}
          pauseLabel={translate('MeetingControls.ContentPauseLabel')}
          unpauseLabel={translate('MeetingControls.ContentUnpauseLabel')}
        />
        <RaiseHandControl />
      </StyledControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
