import { ControlBar } from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';

export const StyledControls = styled.div`
  transition: opacity 250ms ease;
  z-index: 10;

  @media screen and (max-width: 768px) {
    opacity: 1;
  }

  .controls-menu {
    width: 100%;
    position: static;
    height: 3.5rem;
  }

  .inactive {
    background-color: ${props => props.theme.colors.primary.main};
    border-radius: 0.25rem;
  }

  .active {
    background-color: #9bb531;
    border-radius: 0.25rem;
  }
`;

export const StyledControlBar = styled(ControlBar)`
  background-color: ${props => props.theme.navbar.bgd};
  box-shadow: none;
`