import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { VideoTileState } from 'amazon-chime-sdk-js';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ContextState {
  tileId: number | null;
}

const Context = createContext<ContextState | null>(null);

const AudioVideoAttendeesProvider: React.FC = ({ children }) => {
  const audioVideo = useAudioVideo();
  const [tileId, setTileId] = useState<number | null>(null);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const videoTileDidUpdate = (tileState: VideoTileState) => {
      if (
        !tileState.localTile ||
        !tileState.tileId ||
        tileId === tileState.tileId
      ) {
        return;
      }

      setTileId(tileState.tileId);
    };

    audioVideo.addObserver({
      videoTileDidUpdate,
    });
  }, [audioVideo, tileId]);

  const value = useMemo(
    () => ({
      tileId,
    }),
    [tileId]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useAudioVideoAttendees(): ContextState {
  const state = useContext(Context);

  if (!state) {
    throw new Error(
      'useAudioVideoAttendees must be used within AudioVideoAttendeesProvider'
    );
  }

  return state;
}

export { AudioVideoAttendeesProvider, useAudioVideoAttendees };
