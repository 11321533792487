import Axios, { AxiosResponse } from 'axios';
import { EventSession } from '../providers/EventProvider';
import { JoinMeetingResponse } from '../types/JoinMeetingResponse';
import { ResponseBody } from '../types/ResponseBody';
import { UserRights } from '../types/UserRights';
import { getBaseURL } from './configuredURLs';
const host = getBaseURL();
const header = {
  headers: {
    'X-Api-Key': process.env.REACT_APP_API_KEY,
  },
};

/** JOIN */
export const joinChimeMeetingMod = async (
  eventId: string,
  sessionId: string,
  userId: string
): Promise<AxiosResponse<ResponseBody<JoinMeetingResponse>>> => {
  const joinMeetingEndpoint = `${host}/join/${eventId}/${sessionId}/${userId}`;
  return Axios.get(joinMeetingEndpoint, header);
};

/** JOIN */
export const joinChimeMeetingBatch = async (
  eventId: string,
  sessionId: string,
  userId: string
): Promise<AxiosResponse<any>> => {
  const joinMeetingEndpoint = `${host}/bjoin/${eventId}/${sessionId}/${userId}`;
  return Axios.get(joinMeetingEndpoint, header);
};

/** Join 1on1 */

export const join1on1ChimeMeeting = async (
  eventId: string,
  userId: string,
  meetingName: string
): Promise<AxiosResponse<any>> => {
  const body = {
    brandid: eventId,
    userid: userId,
    userlist: meetingName,
    meetingid: '1on1',
  };
  const joinMeetingEndpoint = `${host}/xjoin`;
  return Axios.post(joinMeetingEndpoint, body, header);
};

/** Styling */

export const getStyle = async (
  eventId: string
): Promise<AxiosResponse<any>> => {
  const styleURL = `${host}/style/${eventId}`;
  return Axios.get(styleURL, header);
};

/** Set event */
export const setEvent = async (
  attendeeId: string,
  event: string,
  statusPathParam: string
): Promise<AxiosResponse<any>> => {
  const url = `${host}/event/${event}/${attendeeId}/${statusPathParam}`;
  return Axios.get(url, header);
};

export const setPermissions = async (
  attendeeId: string,
  rights: UserRights[]
): Promise<AxiosResponse<any>> => {
  const url = `${host}/setrights`;
  const body = {
    attendeeid: attendeeId,
    rights: rights,
  };
  return Axios.post(url, body, header);
};

export const getAttendee = async (
  attendeeId: string
): Promise<AxiosResponse<any>> => {
  const attendeeStructEndpoint = `${host}/get/${attendeeId}`;
  return Axios.get(attendeeStructEndpoint, header);
};

export const getPermissions = async (
  Event: EventSession
): Promise<AxiosResponse<any>> => {
  return Axios.get(
    `${host}/ext/${Event.brandId}/${Event.meetingId}/${Event.userId}`,
    header
  );
};

export const endMeetingForAll = async (
  meetingId: string,
  attendeeId: string
): Promise<AxiosResponse<any>> => {
  const endMeetingEndpoint = `${host}/close/${meetingId}/${attendeeId}`;
  return await Axios.get(endMeetingEndpoint, header);
};

export const loadBackgroundImages = async (url: string) => {
  return await Axios.get(url, {
    responseType: 'blob',
    timeout: 30000,
  });
};
