import {
  useApplyVideoObjectFit,
  useAttendeeStatus,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import React, { HTMLAttributes, useEffect, useRef } from 'react';
import VideoTile from '../VideoTile';

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
    BaseSdkProps {
  tileId: number;
  attendeeId: string;
  active?: boolean;
  name?: string;
}

export const RemoteVideo: React.FC<Props> = ({
  name,
  className,
  tileId,
  attendeeId,
  active,
  ...rest
}) => {
  const audioVideo = useAudioVideo();
  const videoEl = useRef<HTMLVideoElement>(null);
  useApplyVideoObjectFit(videoEl);

  const { muted } = useAttendeeStatus(attendeeId);

  useEffect(() => {
    if (!audioVideo || !videoEl.current) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId]);

  return (
    <VideoTile
      {...rest}
      ref={videoEl}
      nameplate={name}
      active={active}
      className={`ch-remote-video--${tileId} ${className || ''}`}
      showMuted={muted}
    />
  );
};

export default RemoteVideo;
