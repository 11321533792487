import {
  Flex,
  Modal,
  ModalBody,
  ModalHeader,
} from 'amazon-chime-sdk-component-library-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import Card from '../components/Card';
import routes from '../constants/routes';
import useTranslate from '../hooks/useTranslate';
import { useJoinMeeting } from '../providers/JoinMeetingProvider';

const MeetingJoinDetails = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const translate = useTranslate();
  const { isOneOnOneMeeting } = useJoinMeeting();

  const handleJoinMeeting = () => {
    if (isOneOnOneMeeting) {
      history.replace(`${routes.ONEONONE}`);
      return;
    } else {
      history.replace(`${routes.MEETING}`);
      return;
    }
  };

  return (
    <>
      <Flex container alignItems="center" flexDirection="column">
        <Button
          variant="secondary"
          label={translate('MeetingJoinDetails.ButtonJoin')}
          onClick={handleJoinMeeting}
        />
      </Flex>
      {error && (
        <Modal size="md" onClose={(): void => setError('')}>
          <ModalHeader title={translate('MeetingJoinDetails.ErrorHeader')} />
          <ModalBody>
            <Card
              title={translate('MeetingJoinDetails.ErrorTitle')}
              description={translate('MeetingJoinDetails.ErrorDescription')}
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MeetingJoinDetails;
