import {
  SpeakerSelection,
  useAudioOutputs,
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import useTranslate from '../../../hooks/useTranslate';

const SpeakerDevices = () => {
  const translate = useTranslate();
  const { devices } = useAudioOutputs();

  return (
    <>
      {devices && (
        <div>
          <SpeakerSelection label={translate('SpeakerDevices.SpeakerLabel')} />
        </div>
      )}
    </>
  );
};

export default SpeakerDevices;
