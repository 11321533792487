import React from 'react';

import { StyledName, StyledDescription } from './Styled';

export interface RosterNameProps {
  name: string;
  descriptions?: string[];
  subtitle?: string;
}

const RosterName = ({ name, subtitle, descriptions }: RosterNameProps) => (
  <StyledName>
    <div className="ch-name">{name}</div>
    {subtitle && <div className="ch-subtitle">{subtitle}</div>}
    {addDescriptions(descriptions)}
  </StyledName>
);

const addDescriptions = (descriptions: string[] = []) => {
  return descriptions.map((description, index) => {
    return (
      <StyledDescription key={index}>
        {description && <div className="ch-description">{description}</div>}
      </StyledDescription>
    );
  });
}

export default RosterName;
