import {
  ScreenShare,
  useContentShareControls,
  useContentShareState,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';
import { useRights } from '../../providers/RightsProvider';
import { UserRights } from '../../types/UserRights';
import RequestPermissionsControl from './RequestPermissionsControl/RequestPermissionsControl';

interface Props {
  label?: string;
  pauseLabel?: string;
  unpauseLabel?: string;
}

const ContentShareControl: React.FC<Props> = ({ label = 'Content' }) => {
  const { hasRight } = useRights();
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();
  const shareAllowed = hasRight(UserRights.ALLOW_ACTIVE_SHARE);

  useEffect(() => {
    if (!shareAllowed) {
      isLocalUserSharing && toggleContentShare();
    }
  }, [isLocalUserSharing, toggleContentShare, shareAllowed]);

  return (
    <>
      {shareAllowed ? (
        <ControlBarButton
          title={label}
          className={isLocalUserSharing ? 'active' : ''}
          icon={<ScreenShare />}
          disabled={!shareAllowed}
          onClick={() => {
            toggleContentShare();
          }}
        />
      ) : (
        <RequestPermissionsControl icon={<ScreenShare />} />
      )}
    </>
  );
};

export default ContentShareControl;
