import { useNavigation } from '../../providers/NavigationProvider';
import MeetingChat from '../MeetingChat/MeetingChat';
import MeetingRoster from '../MeetingRoster';
import MeetingsSettings from '../MeetingSettings';

const NavigationControl = () => {
  const { showRoster, showChat, showSettings } = useNavigation();

  return (
    <>
      {showRoster ? <MeetingRoster /> : <MeetingRoster hidden />}
      {showChat ? <MeetingChat /> : null}
      {showSettings ? <MeetingsSettings /> : null}
    </>
  );
};

export default NavigationControl;
