import React, { useEffect } from 'react';
import MeetingEnd from '../../containers/MeetingEnd';
import { useHeartbeat } from '../../providers/HeartbeatProvider';
import { StyledLayout } from './Styled';

const MeetingEnded = () => {
  const { switchHeartbeatStatus } = useHeartbeat();

  useEffect(() => {
    switchHeartbeatStatus('left');
  }, [switchHeartbeatStatus]);

  return (
    <StyledLayout>
      <MeetingEnd />
    </StyledLayout>
  );
};

export default MeetingEnded;
