import styled from 'styled-components';

interface StyledProps {}

export const StyledMeetingHeader = styled.div<StyledProps>`
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.navbar.bgd};
`;

export const StyledMeetingInfo = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.navbar.bgd};
`;

export const StyledMeetingTitle = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const StyledMeetingControls = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.navbar.bgd};
`;
