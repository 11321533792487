import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';

interface Props {
  height: string;
  width: string;
}

const Logo: React.FC<Props> = ({ height, width }) => {
  const { joinInfo } = useJoinMeeting();
  const translate = useTranslate();

  return (
    <img
      src={joinInfo?.meeting.brandlogo}
      height={height}
      width={width}
      alt={translate('Logo.altText')}
    />
  );
};

export default Logo;
