import React, { createContext, useContext, useEffect, useState } from 'react';
import { Layout } from '../components/Videos/VideoGrid';
import { useJoinMeeting } from './JoinMeetingProvider';

interface VideoLayoutState {
  setActiveSpeakerLayout: () => void;
  setFeaturedLayout: () => void;
  setGridLayout: () => void;
  layout: Layout;
}

const defaultVideoLayout = {
  setActiveSpeakerLayout: () => {},
  setFeaturedLayout: () => {},
  setGridLayout: () => {},
  layout: null,
};

const Context = createContext<VideoLayoutState>(defaultVideoLayout);

const VideoGridLayoutProvider: React.FC = ({ children }) => {
  const [layout, setLayout] = useState<Layout>('standard');
  const { joinInfo } = useJoinMeeting();

  useEffect(() => {
    if (joinInfo?.meeting.startlayout) {
      setLayout(joinInfo?.meeting.startlayout as Layout);
    }
  }, [joinInfo]);

  const setActiveSpeakerLayout = () => {
    setLayout('featured');
  };

  const setFeaturedLayout = () => {
    setLayout('featured');
  };

  const setGridLayout = () => {
    setLayout('standard');
  };

  return (
    <Context.Provider
      value={{
        layout,
        setActiveSpeakerLayout,
        setFeaturedLayout,
        setGridLayout
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useVideoGridLayout = () => {
  const gridLayout = useContext(Context);
  if (!gridLayout) {
    console.log('usegridLayout must be used within VideoGridLayoutProvider');
  }

  return gridLayout;
};

export { VideoGridLayoutProvider, useVideoGridLayout };
