import {
  useAudioVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { User } from '../types/User';
import { getAttendee } from '../utils/api';
import { useJoinMeeting } from './JoinMeetingProvider';

export type ChatMessageType = {
  name: string;
  message: string;
  attendeeId: string;
  timeStamp: number;
};

interface ChatContextValue {
  chatMessages: ChatMessageType[];
  newChatMessageCount: number;
  addLocalUserChatMessage: (message: string) => void;
  clearNewMessageCount: () => void;
}

const ChatContext = React.createContext<ChatContextValue | null>(null);

const ChatProvider: React.FC = ({ children }) => {
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const { joinInfo } = useJoinMeeting();
  const [chatMessages, setChatMessages] = useState<ChatMessageType[]>([]);
  const [newChatMessageCount, setNewChatMessageCount] = useState<number>(0);
  const localUserName = joinInfo?.user.fullname || 'Me';

  const addLocalUserChatMessage = useCallback(
    (message: string) => {
      setChatMessages((oldMessages) => [
        ...oldMessages,
        {
          name: localUserName,
          message: message,
          attendeeId: 'local',
          timeStamp: Date.now(),
        },
      ]);
    },
    [localUserName]
  );

  const clearNewMessageCount = () => {
    setNewChatMessageCount(0);
  };

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const chatCallback = async (data: DataMessage) => {
      if (data.topic !== 'Chat') {
        return;
      }

      const response = await getAttendee(data.senderAttendeeId);

      const responseBody: User = response.data.data;

      const senderName = responseBody.fullname;

      setChatMessages((oldMessages) => [
        ...oldMessages,
        {
          name: senderName,
          message: data.text(),
          attendeeId: data.senderAttendeeId,
          timeStamp: data.timestampMs,
        },
      ]);
      setNewChatMessageCount((oldNumber) => oldNumber + 1);
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage('Chat', chatCallback);

    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage('Chat');
    };
  }, [audioVideo, meetingManager?.meetingSessionConfiguration?.meetingId]);

  const value = useMemo(
    () => ({
      chatMessages,
      newChatMessageCount,
      addLocalUserChatMessage,
      clearNewMessageCount,
    }),
    [chatMessages, newChatMessageCount, addLocalUserChatMessage]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

function useChatState(): ChatContextValue {
  const state = useContext(ChatContext);

  if (!state) {
    throw new Error('userChatState must be used within ChatProvider');
  }

  return state;
}

export { ChatProvider, useChatState };
