import {
  Check,
  Clear,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useRef, useState } from 'react';
import { useChatState } from '../../../providers/ChatProvider';
import { useJoinMeeting } from '../../../providers/JoinMeetingProvider';
import {
  StyledChatInput,
  StyledChatInputWrapper,
  StyledClear,
  StyledSend,
} from './Styled';

/* interface Props extends Omit<RosterCellProps, 'name'> {
  attendeeId: string;
  extraIcon?: boolean;
} */

export const ChatInput: React.FC = () => {
  const audioVideo = useAudioVideo();
  const { addLocalUserChatMessage } = useChatState();
  const { isOneOnOneMeeting } = useJoinMeeting();

  const [text, setText] = useState<string>('');
  const inputEl = useRef<HTMLInputElement>(null);
  const lifetime = 300000; //ms

  const save = (text: string) => {
    if (text !== '') {
      addLocalUserChatMessage(text);
      audioVideo?.realtimeSendDataMessage('Chat', text, lifetime);
      setText('');
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setText(e.target.value);
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        save(text);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return (
    <StyledChatInputWrapper isOneOnOneMeeting={isOneOnOneMeeting}>
      <StyledChatInput
        value={text}
        type={'text'}
        ref={inputEl}
        className="ch-input"
        onChange={handleChange}
      />
      <StyledSend
        className="send-icon"
        onClick={() => {
          save(text);
        }}
      >
        <Check width="1.25rem" />
      </StyledSend>
      <StyledClear
        className="clear-icon"
        onClick={() => {
          setText('');
        }}
      >
        <Clear width="1.25rem" />
      </StyledClear>
    </StyledChatInputWrapper>
  );
};

export default ChatInput;
