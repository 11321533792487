import {
  Attendees,
  Chat,
  Cog,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useState } from 'react';
import { useChatState } from '../../providers/ChatProvider';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import { useHandRaise } from '../../providers/MeetingMessagesProvider/HandRaiseProvider';
import { useNavigation } from '../../providers/NavigationProvider';
import NavbarBadgeItem from './NavbarBadgeItem';
import { StyledNavigationControls } from './Styled';

const NavigationControls = () => {
  const { toggleRoster, toggleChat, toggleSettings, showChat } =
    useNavigation();
  const { raiseHandAttendeeIds } = useHandRaise();
  const { newChatMessageCount, clearNewMessageCount } = useChatState();
  const { isOneOnOneMeeting } = useJoinMeeting();
  const { roster } = useRosterState();

  const [numberOfAttendees, setNumberOfAttendees] = useState(0);

  useEffect(() => {
    const attendees = Object.values(roster);
    setNumberOfAttendees(attendees.length);
  }, [roster]);
  const handRaiseBadge = raiseHandAttendeeIds.length || undefined;
  const chatBadge = newChatMessageCount || undefined;

  const onToggleChat = () => {
    toggleChat();
    clearNewMessageCount();
  };

  return (
    <StyledNavigationControls>
      <NavbarBadgeItem
        icon={<Attendees />}
        onClick={toggleRoster}
        badge={isOneOnOneMeeting ? numberOfAttendees : handRaiseBadge}
        status={'alert'}
      />
      <NavbarBadgeItem
        icon={<Chat />}
        onClick={onToggleChat}
        badge={showChat ? undefined : chatBadge}
        status={'alert'}
      />
      <NavbarBadgeItem icon={<Cog />} onClick={toggleSettings} />
    </StyledNavigationControls>
  );
};

export default NavigationControls;
