import styled from 'styled-components';
import Button from '../../components/Button';

export const StyledEndMeetingControl = styled.div`
  margin-left: 1rem;
`;

export const StyledP = styled.p`
  padding: 0.5rem;
`;

export const StyledLeaveButton = styled(Button)`
  padding: 0.25rem 0.5rem !important;
`;
