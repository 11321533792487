import {
  CheckRound,
  HandRaise,
  Remove
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import styled from 'styled-components';
import useTranslate from '../../hooks/useTranslate';
import { useRightsManagement } from '../../providers/MeetingMessagesProvider/RightsManagementProvider';
import { useRights } from '../../providers/RightsProvider';
import { RosterAttendeeType } from '../../types/RosterAttendeeType';
import { UserRights } from '../../types/UserRights';
import IconButton from '../Button/IconButton';

type Props = {
  attendee: RosterAttendeeType;
};

type ChangeRightsRowProps = {
  label: string;
  attendee: RosterAttendeeType;
  decideRights: UserRights[];
  onGrantRights: (granted: UserRights[]) => void;
  onRevokeRights: (revoked: UserRights[]) => void;
};

const ChangeRightsRow: React.FC<ChangeRightsRowProps> = ({
  onGrantRights,
  onRevokeRights,
  attendee,
  label,
  decideRights,
}) => {
  const attendeeRights = attendee.rights;
  const attendeeRequestedRights = attendee.requestedRights;
  const activeRights = decideRights.reduce((prev, current) => {
    return prev && attendeeRights.has(current);
  }, true);

  const inActiveRights = decideRights.reduce((prev, current) => {
    return prev && !attendeeRights.has(current);
  }, true);

  const isRequested = decideRights.reduce((prev, current) => {
    return prev && attendeeRequestedRights.has(current);
  }, true);

  return (
    <StyledMenuItem>
      <StyledMenuLabel>{label}</StyledMenuLabel>
      {isRequested ? <HandRaise /> : null}
      <StyledIconButton
        active={activeRights}
        icon={<CheckRound />}
        onClick={() => onGrantRights(decideRights)}
      />
      <StyledIconButton
        inactive={inActiveRights}
        icon={<Remove />}
        onClick={() => onRevokeRights(decideRights)}
      />
    </StyledMenuItem>
  );
};

export const ChangeRightsMenu: React.FC<Props> = ({ attendee }) => {
  const { hasRight } = useRights();
  const { updateAttendeeRights } = useRightsManagement();
  const translate = useTranslate();
  const attendeeId = attendee.id;

  const mayDisableVideo = hasRight(UserRights.ALLOW_DISABLE_CAMERA);
  const mayDisableAudio = hasRight(UserRights.ALLOW_MUTE);

  const handleRevokeRights = (revoked: UserRights[]) => {
    updateAttendeeRights({
      attendeeId,
      revoked,
      granted: [],
    });
  };

  const handleGrantRights = (granted: UserRights[]) => {
    updateAttendeeRights({
      attendeeId,
      granted,
      revoked: [],
    });
  };

  return (
    <>
      <StyledMenuTitle>{translate('ChangeRightsMenu.Title')}</StyledMenuTitle>
      {mayDisableVideo ? (
        <>
          <ChangeRightsRow
            label={translate('ChangeRightsMenu.VideoRight')}
            attendee={attendee}
            decideRights={[UserRights.ALLOW_ACTIVE_CAMERA]}
            onGrantRights={handleGrantRights}
            onRevokeRights={handleRevokeRights}
          />
          <ChangeRightsRow
            label={translate('ChangeRightsMenu.ShareRight')}
            attendee={attendee}
            decideRights={[UserRights.ALLOW_ACTIVE_SHARE]}
            onGrantRights={handleGrantRights}
            onRevokeRights={handleRevokeRights}
          />
        </>
      ) : null}
      {mayDisableAudio ? (
        <ChangeRightsRow
          label={translate('ChangeRightsMenu.AudioRight')}
          attendee={attendee}
          decideRights={[UserRights.ALLOW_ACTIVE_AUDIO]}
          onGrantRights={handleGrantRights}
          onRevokeRights={handleRevokeRights}
        />
      ) : null}
      {mayDisableAudio && mayDisableVideo ? (
        <ChangeRightsRow
          label={translate('ChangeRightsMenu.AllRights')}
          attendee={attendee}
          decideRights={[
            UserRights.ALLOW_ACTIVE_CAMERA,
            UserRights.ALLOW_ACTIVE_AUDIO,
            UserRights.ALLOW_ACTIVE_SHARE,
          ]}
          onGrantRights={handleGrantRights}
          onRevokeRights={handleRevokeRights}
        />
      ) : null}
    </>
  );
};

export default ChangeRightsMenu;

const StyledMenuTitle = styled.div`
  margin: 0.25rem 1rem 0.75rem 1rem;
  color: ${(props) => props.theme.colors.info.lightest};
`;

const StyledMenuItem = styled.li`
  display: flex;
  place-content: center;
  color: ${(props) => props.theme.colors.info.lightest};
`;

const StyledMenuLabel = styled.div`
  height: 1rem;
  margin: auto auto auto 1rem;
  color: ${(props) => props.theme.colors.info.lightest};
`;

const StyledIconButton = styled(IconButton)<{
  active?: boolean;
  inactive?: boolean;
}>`
  .ch-icon {
  }

  svg {
    color: ${(props) => props.inactive && props.theme.colors.primary.lightest}!important;
    color: ${(props) => props.active && props.theme.colors.success.light}!important;
  }
`;
