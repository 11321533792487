import React from 'react';
import Badge from '../../components/Badge';
import ControlBarButton from '../../components/Button/ControlBarItem';
import { StyledNavbarBadgeItem } from './Styled';

export interface NavbarBadgeItemProps {
  icon: JSX.Element;
  onClick: () => void;
  label?: string;
  isSelected?: boolean;
  badge?: number;
  status?: 'alert' | 'default';
}

export const NavbarBadgeItem = ({
  onClick,
  label,
  icon,
  isSelected = false,
  badge,
  status='default'
}: NavbarBadgeItemProps) => (
  <StyledNavbarBadgeItem data-testid="navbar-item">
    <ControlBarButton onClick={onClick} icon={icon} isSelected={isSelected}/>
    {badge && badge > 0 && <Badge className={'badge'} value={badge} status={status} />}
    {label && (
      <span
        data-testid="navbar-label"
        className="ch-navigation-bar-item-label"
        onClick={onClick}
      >
        {label}
      </span>
    )}
  </StyledNavbarBadgeItem>
);

export default NavbarBadgeItem;
