import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { de } from '../i18n/de';
import { en } from '../i18n/en';

const DE_LOCALE = 'de';
const EN_LOCALE = 'en';

type APP_LOCALE = 'de' | 'en';

let messages = {
  [DE_LOCALE]: de,
  [EN_LOCALE]: en,
};

let SELECTED_LOCALE: APP_LOCALE = 'de';

type Props = {
  children: ReactNode;
};

export default function I18nProvider(props: Props) {
  const { children } = props;

  const checkBrowserLanguage = (): APP_LOCALE => {
    if (navigator.language.includes('en')) {
      SELECTED_LOCALE = 'en';
    }
    return SELECTED_LOCALE;
  };

  return (
    <IntlProvider
      locale={SELECTED_LOCALE}
      messages={messages[checkBrowserLanguage()]}
    >
      {children}
    </IntlProvider>
  );
}
