import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider as ChimeThemeProvider } from 'styled-components';
import darkTheme from '../theme/dark';
import GlobalStyles from '../theme/GlobalStyles';
import lightTheme from '../theme/light';
import { getStyle } from '../utils/api';

export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark',
}

const storageKey: string = 'str8ActiveThemeName';

interface ThemeValue {
  activeThemeName: ThemeName;
  setTheme: (themeName: ThemeName) => void;
}

interface ThemeProps {
  children: ReactNode;
}

const themes: { name: ThemeName; value: any }[] = [
  {
    name: ThemeName.LIGHT,
    value: lightTheme,
  },
  {
    name: ThemeName.DARK,
    value: darkTheme,
  },
];

export const useTheme = () => {
  const state = useContext(ThemeContext);

  if (!state) {
    throw new Error('useTheme must be used within ThemeProvider');
  }

  return state;
};

const defaultThemeName = ThemeName.LIGHT;
const ThemeContext = createContext<ThemeValue | null>(null);

const saveThemeNameToStorage = (themeName: ThemeName): void => {
  return localStorage.setItem(storageKey, themeName);
};

const getThemeNameFromtStorage = (): ThemeName | null => {
  return localStorage.getItem(storageKey) as ThemeName | null;
};

const ThemeProvider = ({ children }: ThemeProps) => {
  const [activeThemeName, setActiveThemeName] = useState<ThemeName>(
    getThemeNameFromtStorage() || defaultThemeName
  );

  const setThemeName = (themeName: ThemeName) => {
    if (themeName !== ThemeName.LIGHT && themeName !== ThemeName.DARK) {
      return;
    }
    setActiveThemeName(themeName);
    saveThemeNameToStorage(themeName);
  };

  const getTheme = (themeName: ThemeName) => {
    const theme = themes.find((theme) => theme.name === themeName)?.value;
    if (theme) {
      return theme;
    }
  };

  useEffect(() => {
    const getStyles = async (): Promise<void> => {
      const urlParams = new URLSearchParams(window.location.search);
      let brandId = urlParams.get('brand')?.trim();
      if (!brandId) return;

      const response = await getStyle(brandId);
      const responseBody = response.data.data;
      setThemeName(responseBody.theme);
    };
    getStyles();
  }, []);

  const ThemeValue: ThemeValue = {
    activeThemeName,
    setTheme: (themeName: ThemeName) => setThemeName(themeName),
  };

  return (
    <ThemeContext.Provider value={ThemeValue}>
      <ChimeThemeProvider theme={getTheme(activeThemeName)}>
        <GlobalStyles />
        {children}
      </ChimeThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
