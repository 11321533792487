import {
  BackgroundBlurProvider,
  MeetingProvider,
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react';
import React, { FC } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import routes from './constants/routes';
import Notifications from './containers/Notifications';
import { AppStateProvider } from './providers/AppStateProvider';
import { ChatProvider } from './providers/ChatProvider';
import ErrorProvider from './providers/ErrorProvider';
import EventProvider from './providers/EventProvider';
import { HeartbeatProvider } from './providers/HeartbeatProvider';
import I18nProvider from './providers/I18nProvider';
import { JoinMeetingProvider } from './providers/JoinMeetingProvider';
import { NavigationProvider } from './providers/NavigationProvider';
import { RightsProvider } from './providers/RightsProvider';
import { StartMeetingProvider } from './providers/StartMeetingProvider';
import ThemeProvider from './providers/ThemeProvider';
import VideoBackgroundProvider from './providers/VideoBackgroundProvider';
import { DeviceSetup, Home, Meeting } from './views';
import AttendeeKicked from './views/AttendeeKicked';
import MeetingEnded from './views/MeetingEnded';
import MeetingLeft from './views/MeetingLeft';

const App: FC = () => (
  <I18nProvider>
    <HashRouter>
      <AppStateProvider>
        <ThemeProvider>
          <NotificationProvider>
            <EventProvider>
              <Notifications />
              <ErrorProvider>
                <MeetingProvider>
                  <RightsProvider>
                    <JoinMeetingProvider>
                      <HeartbeatProvider>
                        <NavigationProvider>
                          <BackgroundBlurProvider>
                            <VideoBackgroundProvider>
                              <Switch>
                                <Route
                                  exact
                                  path={routes.HOME}
                                  component={Home}
                                />
                                <Route path={routes.DEVICE}>
                                  <DeviceSetup />
                                </Route>
                                <Route path={routes.MEETING}>
                                  <StartMeetingProvider>
                                    <ChatProvider>
                                      <Meeting />
                                    </ChatProvider>
                                  </StartMeetingProvider>
                                </Route>
                                <Route path={routes.ONEONONE}>
                                  <StartMeetingProvider>
                                    <ChatProvider>
                                      <Meeting />
                                    </ChatProvider>
                                  </StartMeetingProvider>
                                </Route>
                                <Route path={routes.ENDED}>
                                  <MeetingEnded />
                                </Route>
                                <Route path={routes.LEAVE}>
                                  <MeetingLeft />
                                </Route>
                                <Route path={routes.KICKED}>
                                  <AttendeeKicked />
                                </Route>
                              </Switch>
                            </VideoBackgroundProvider>
                          </BackgroundBlurProvider>
                        </NavigationProvider>
                      </HeartbeatProvider>
                    </JoinMeetingProvider>
                  </RightsProvider>
                </MeetingProvider>
              </ErrorProvider>
            </EventProvider>
          </NotificationProvider>
        </ThemeProvider>
      </AppStateProvider>
    </HashRouter>
  </I18nProvider>
);

export default App;
