import {
  LeaveMeeting,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtonGroup,
  ModalHeader,
  useAudioVideo,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';
import useTranslate from '../../hooks/useTranslate';
import { useAppState } from '../../providers/AppStateProvider';
import { useLocalAttendee } from '../../providers/LocalAttendeeProvider';
import { useRights } from '../../providers/RightsProvider';
import { DataMessageType } from '../../types/MeetingMessage';
import { UserRights } from '../../types/UserRights';
import { endMeetingForAll } from '../../utils/api';
import { StyledEndMeetingControl, StyledLeaveButton, StyledP } from './Styled';

const EndMeetingControl: React.FC = () => {
  const meetingManager = useMeetingManager();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (): void => setShowModal(!showModal);
  const { meetingId } = useAppState();
  const history = useHistory();
  const translate = useTranslate();
  const audioVideo = useAudioVideo();
  const { validateAction, hasRight } = useRights();
  const { localAttendee } = useLocalAttendee();

  const canEndMeeting = hasRight(UserRights.ALLOW_CLOSE_MEETING);

  const hideButton = {
    display: `none`,
  };

  const leaveMeeting = async (): Promise<void> => {
    audioVideo?.stopVideoInput();
    audioVideo?.stopAudioInput();
    audioVideo?.stop();
    setTimeout(() => {
      history.replace(routes.LEAVE);
    }, 100);
  };

  const endMeeting = async (): Promise<void> => {
    try {
      if (meetingId) {
        await endMeetingForAll(meetingId, localAttendee.id);
        const messageData: DataMessageType = {
          eventName: 'meetingStatus',
          eventStatus: 'ended',
          attendeeId: '',
        };
        meetingManager.audioVideo?.realtimeSendDataMessage(
          'meetingStatus',
          messageData,
          300000
        );
        audioVideo?.stopVideoInput();
        audioVideo?.stopAudioInput();
        audioVideo?.stop();
        await meetingManager.leave().then(() => {
          history.replace(routes.ENDED);
        });
      }
    } catch (e) {
      console.log('Could not end meeting', e);
    }
  };

  return (
    <StyledEndMeetingControl>
      <StyledLeaveButton
        variant="primary"
        icon={<LeaveMeeting />}
        onClick={toggleModal}
        label={translate('MeetingControls.EndLabel')}
      />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title={translate('EndMeetingControl.ModalTitle')} />
          <ModalBody>
            <StyledP>{translate('EndMeetingControl.ModalText')}</StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                style={canEndMeeting ? undefined : hideButton}
                onClick={() =>
                  validateAction(UserRights.ALLOW_CLOSE_MEETING, endMeeting)
                }
                variant="primary"
                label={translate('EndMeetingControl.ModalButtonEnd')}
                closesModal
              />,
              <ModalButton
                onClick={leaveMeeting}
                variant="primary"
                label={translate('EndMeetingControl.ModalButtonLeave')}
                closesModal
              />,
              <ModalButton
                variant="secondary"
                label={translate('EndMeetingControl.ModalButtonCancel')}
                closesModal
              />,
            ]}
          />
        </Modal>
      )}
    </StyledEndMeetingControl>
  );
};

export default EndMeetingControl;
