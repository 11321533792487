import React, { useEffect } from 'react';
import KickedAttendee from '../../containers/KickedAttendee';
import { useHeartbeat } from '../../providers/HeartbeatProvider';
import { StyledLayout } from './Styled';

const AttendeeKicked = () => {
  const { switchHeartbeatStatus } = useHeartbeat();

  useEffect(() => {
    switchHeartbeatStatus('left');
  }, [switchHeartbeatStatus]);

  return (
    <StyledLayout>
      <KickedAttendee />
    </StyledLayout>
  );
};

export default AttendeeKicked;
