import {
  useAudioVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes';
import { RosterAttendeeType } from '../types/RosterAttendeeType';
import { useJoinMeeting } from './JoinMeetingProvider';
import { useRosterState } from './RosterProvider';

type Props = {
  children: ReactNode;
};
const MaxAttendeesContext = React.createContext(null);

export function MaxAttendeesProvider({ children }: Props) {
  const { joinInfo } = useJoinMeeting();
  const { roster } = useRosterState();
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const history = useHistory();
  let attendees = Object.values(roster);

  useEffect(() => {
    const kickAttendee = async (): Promise<void> => {
      if (audioVideo) {
        audioVideo.stopVideoInput();
        audioVideo.stopAudioInput();
        audioVideo.stopContentShare();
        audioVideo.stop();
      }

      await meetingManager.leave().then(() => {
        history.replace(routes.KICKED);
      });
    };

    if (
      joinInfo?.meeting.maxparticipants &&
      attendees.length > joinInfo?.meeting.maxparticipants
    ) {
      const joinTimes = attendees.map(
        (attendee: RosterAttendeeType) => attendee.joinon
      );
      const lastJoined = Math.max(...joinTimes);
      const lastAttendee = attendees.find(
        (attendee: RosterAttendeeType) => attendee.joinon === lastJoined
      );

      if (lastAttendee?.id === joinInfo.user.id) {
        kickAttendee();
      }
    }
  }, [joinInfo, attendees, history, meetingManager, audioVideo]);

  return (
    <MaxAttendeesContext.Provider value={null}>
      {children}
    </MaxAttendeesContext.Provider>
  );
}
