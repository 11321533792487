import { Flex, Heading } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const MeetingDetails = () => {
  const translate = useTranslate();

  return (
    <Flex container layout="fill-space-centered">
      <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
        <Heading level={4} tag="h1" mb={2}>
          {translate('MeetingDetails.Info')}
        </Heading>
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
