import {
  useFeaturedTileState,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import React, { memo } from 'react';
import { useRosterState } from '../../../providers/RosterProvider';
import { RemoteVideo } from '../RemoteVideo';

export const RemoteVideos: React.FC<BaseSdkProps> = (props) => {
  const { roster } = useRosterState();
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();
  const { tileId: featuredTileId } = useFeaturedTileState();

  return (
    <>
      {tiles.map((tileId) => {
        const attendee = roster[tileIdToAttendeeId[tileId]];
        const  fullname = attendee?.fullname || '';

        return (
          <RemoteVideo
            {...props}
            key={tileId}
            tileId={tileId}
            name={fullname}
            active={featuredTileId === tileId}
            attendeeId={attendee?.attendeeid}
          />
        );
      })}
    </>
  );
};

export default memo(RemoteVideos);
