import styled from 'styled-components';

interface Props {
  showRoster: boolean;
  showChat: boolean;
  showSettings: boolean;
  isOneOnOneMeeting: boolean | null;
}

export const StyledLayout = styled.main<Props>`
  height: 100vh;
  width: 100%;

  display: grid;

  .roster {
    grid-area: roster;
    z-index: 2;
  }

  .chat {
    grid-area: chat;
    z-index: 2;
  }

  .settings {
    grid-area: settings;
    z-index: 2;
  }

  ${({ showRoster, showChat, showSettings, isOneOnOneMeeting }) => {

    if (isOneOnOneMeeting) {

      if (showRoster) {
        return `
        .roster {
          grid-area: content;
          width: 100%;
        }
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'header' 'content' 'controls';
      `;
      }

      if (showSettings) {
        return `
        .settings {
          grid-area: content;
          width: 100%;
        }
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'header' 'content' 'controls';
      `;
      }

      if (showChat) {
        return `
        .chat {
          grid-area: content;
          width: 100%;
        }
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'header' 'content' 'controls';
      `;
      }

      return `
      grid-template-rows: 3.5rem 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: 'header' 'content' 'controls';
    `;
    }

    if (showRoster) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'header header' 'content roster';
      `;
    }

    if (showSettings) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'header header' 'content settings';
      `;
    }

    if (showChat) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'header header' 'content chat';
      `;
    }

    return `
      grid-template-rows: 3.5rem 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: 'header' 'content';
    `;
  }}

`;

export const StyledHeader = styled.div`
  grid-area: header;
`;

export const StyledContent = styled.div`
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  flex-direction: column;

  .videos {
    flex: 1;
  }

`;
