import styled from 'styled-components';

interface Props {
  isOneOnOneMeeting: boolean | null;
}

export const StyledChatInputWrapper = styled.span<Props>`
  background-color: ${(props) => props.theme.inputs.bgd};
  border: ${(props) => props.theme.inputs.border};
  margin-top: auto;
  display: flex;
  flex-direction: row;

  &:focus-within,
  &[aria-invalid='true']:focus-within {
    border: ${(props) => props.theme.inputs.focus.border};
  }

  .send-icon {
    color: ${(props) => props.theme.inputs.fontColor};
    border: none;
    background: none;
    cursor: pointer;
  }

  .clear-icon {
    color: ${(props) => props.theme.inputs.fontColor};
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const StyledChatInput = styled.input`
  flex-grow: 1;
  align-items: center;
  display: flex;
  padding: 0.5rem;
  letter-spacing: -0.005625rem;
  transition: box-shadow 0.05s ease-in;
  background-color: ${(props) => props.theme.inputs.bgd};
  border: none;
  color: ${(props) => props.theme.inputs.fontColor};
  font-size: ${(props) => props.theme.fontSizes.text.fontSize};
  line-height: ${(props) => props.theme.fontSizes.text.lineHeight};

  &::placeholder {
    color: ${(props) => props.theme.inputs.placeholder};
  }

  &:focus,
  &[aria-invalid='true']:focus {
    outline: none;
  }

  &[aria-invalid='true'] {
    border: ${(props) => props.theme.inputs.error.border};
    box-shadow: ${(props) => props.theme.inputs.error.shadow};
  }

  // Hides native clear button
  &::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
`;

export const StyledSend = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
`;

export const StyledClear = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }
`;
