import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import BackendEvents from '../../enums/BackendEvents';
import { DataMessageType } from '../../types/MeetingMessage';

interface HandRaiseContextValue {
  raiseHandAttendeeIds: string[];
  toggleRaiseHand: (attendeeId: string, eventStatus: string) => void;
}

const HandRaiseContext = createContext<HandRaiseContextValue | null>(null);

const HandRaiseProvider: React.FC = ({ children }) => {
  const audioVideo = useAudioVideo();

  const [raiseHandAttendeeIds, setRaiseHandAttendeeId] = useState<string[]>([]);

  const toggleRaiseHand = useCallback(
    (attendeeId: string, eventStatus: string) => {
      if (eventStatus === 'on') {
        setRaiseHandAttendeeId((oldIds) => [...oldIds, attendeeId]);
      }
      if (eventStatus === 'off') {
        setRaiseHandAttendeeId((oldIds) =>
          oldIds.filter((id: string) => id !== attendeeId)
        );
      }
    },
    []
  );

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const messageCallback = async (data: DataMessage) => {
      if (data.topic !== BackendEvents.RaiseHand) {
        return;
      }
      const rasieHandEvent: DataMessageType = data.json();

      if (rasieHandEvent.eventStatus === 'on') {
        setRaiseHandAttendeeId((oldIds) => [
          ...oldIds,
          rasieHandEvent.attendeeId,
        ]);
      }
      if (rasieHandEvent.eventStatus === 'off') {
        setRaiseHandAttendeeId((oldIds) =>
          oldIds.filter((id: string) => id !== rasieHandEvent.attendeeId)
        );
      }
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      BackendEvents.RaiseHand,
      messageCallback
    );

    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
        BackendEvents.RaiseHand
      );
    };
  }, [audioVideo]);

  const value = useMemo(
    () => ({ raiseHandAttendeeIds, toggleRaiseHand }),
    [raiseHandAttendeeIds, toggleRaiseHand]
  );

  return (
    <HandRaiseContext.Provider value={value}>
      {children}
    </HandRaiseContext.Provider>
  );
};

function useHandRaise(): HandRaiseContextValue {
  const state = useContext(HandRaiseContext);

  if (!state) {
    throw new Error('useHandRaise must be used within RaiseHandProvider');
  }

  return state;
}

export { HandRaiseProvider, useHandRaise };
