import {
  Microphone,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';
import { useRights } from '../../providers/RightsProvider';
import { UserRights } from '../../types/UserRights';
import RequestPermissionsControl from './RequestPermissionsControl/RequestPermissionsControl';

const AudioInputControl: React.FC = () => {
  const { hasRight } = useRights();
  const { muted, toggleMute } = useToggleLocalMute();
  const microphoneAllowed = hasRight(UserRights.ALLOW_ACTIVE_AUDIO);

  useEffect(() => {
    if (!microphoneAllowed) {
      !muted && toggleMute();
    }
  }, [microphoneAllowed, muted, toggleMute]);

  return (
    <>
      {microphoneAllowed ? (
        <ControlBarButton
          className={muted ? 'inactive' : undefined}
          icon={<Microphone muted={muted} />}
          disabled={!microphoneAllowed}
          onClick={() => {
            toggleMute();
          }}
        />
      ) : (
        <RequestPermissionsControl icon={<Microphone muted />} />
      )}
    </>
  );
};

export default AudioInputControl;
