import Axios from 'axios';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useEventContext } from './EventProvider';
import { useJoinMeeting } from './JoinMeetingProvider';

export type HeartbeatStatus = 'setup' | 'joined' | 'left' | undefined;
const defaultTimeout = 15000; //ms

export type HeartbeatContextType = {
  heartbeatStatus: HeartbeatStatus;
  heartbeatTimeout: number;
  switchHeartbeatStatus: (status: HeartbeatStatus) => void;
};

type Props = {
  children: ReactNode;
};

const HeartbeatContext = React.createContext<HeartbeatContextType | null>(null);

const HeartbeatProvider = ({ children }: Props) => {
  const event = useEventContext();
  const { joinInfo } = useJoinMeeting();

  const [heartbeatStatus, setHeartbeatStatus] = useState<HeartbeatStatus>(
    undefined
  );
  const [heartbeatTimeout, setHeartbeatTimeout] = useState<number>(
    defaultTimeout
  );
  const [heartbeatCounter, setHeartbeatCounter] = useState<number>(0);
  let heartbeatEndpoint = joinInfo?.meeting?.heartbeat || '';

  const heartbeat = useCallback(
    async (
      brandId: string,
      meetingId: string,
      userId: string,
      status: HeartbeatStatus
    ): Promise<any> => {
      if (!heartbeatEndpoint || heartbeatEndpoint === '') {
        return;
      }
      const heartbeatUrl = `${heartbeatEndpoint}?brand=${brandId}&meeting=${meetingId}&user=${userId}&status=${status}`;
      return Axios.get(heartbeatUrl);
    },
    [heartbeatEndpoint]
  );

  useEffect(() => {
    if (!heartbeatEndpoint || heartbeatEndpoint === '') {
      return;
    }
    heartbeat(
      event.brandId,
      event.meetingId as string,
      event.userId,
      heartbeatStatus
    ).then((res: any) => {
      if (res.data.delay) {
        setHeartbeatTimeout(res.data.delay * 1000);
      }
    });
  }, [
    heartbeatStatus,
    event.brandId,
    event.meetingId,
    event.userId,
    heartbeat,
    heartbeatEndpoint,
  ]);

  const sendHeartbeat = () => {
    if (!heartbeatEndpoint || heartbeatEndpoint === '') {
      return;
    }
    setTimeout(() => {
      heartbeat(
        event.brandId,
        event.meetingId as string,
        event.userId,
        heartbeatStatus
      )
        .then((res: any) => {
          if (res.data.delay) {
            if (res.data.delay < 2) {
              res.data.delay = 2;
            }
            if (res.data.delay > 60) {
              res.data.delay = 60;
            }
            setHeartbeatTimeout(res.data.delay * 1000);
            setHeartbeatCounter(heartbeatCounter + 1);
          } else {
            setHeartbeatTimeout(defaultTimeout);
            setHeartbeatCounter(heartbeatCounter + 1);
          }
        })
        .catch(() => {
          setHeartbeatCounter(heartbeatCounter + 1);
          setHeartbeatTimeout(defaultTimeout);
        });
    }, heartbeatTimeout);
  };

  useEffect(() => {
    if (heartbeatStatus === 'joined') {
      sendHeartbeat();
    }
    if (heartbeatStatus === 'setup') {
      sendHeartbeat();
    }
    if (heartbeatStatus === 'left') {
      sendHeartbeat();
    }
  }, [heartbeatStatus, heartbeatCounter]);     // eslint-disable-line react-hooks/exhaustive-deps


  const switchHeartbeatStatus = useCallback((status: HeartbeatStatus): void => {
    setHeartbeatStatus(status);
  }, []);

  const providerValue = {
    heartbeatStatus,
    heartbeatTimeout,
    switchHeartbeatStatus,
  };
  return (
    <HeartbeatContext.Provider value={providerValue}>
      {children}
    </HeartbeatContext.Provider>
  );
};

const useHeartbeat = (): HeartbeatContextType => {
  const context = useContext(HeartbeatContext);
  if (!context) {
    throw Error('Use useHeartbeat in HeartbeatProvider');
  }
  return context;
};

export { HeartbeatProvider, useHeartbeat };
