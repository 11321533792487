import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  PopOverMenu,
  PopOverMenuProps,
} from '../../../components/Roster/RosterCell/PopOverMenu';
import RequestPermissionsMenu from './RequestPermissionsMenu';

const RequestPermissionsControl: FC<RequestPermissionsControlProps> = ({
  icon,
  ...rest
}) => {
  const { colors } = useTheme();

  return (
    <PopOverMenu
      menu={<RequestPermissionsMenu />}
      buttonProps={{
        icon,
        style: {
          backgroundColor: colors?.primary.darkest,
          borderRadius: '5px',
          margin: '0.25rem',
        },
      }}
      {...rest}
    />
  );
};

type RequestPermissionsControlProps = Omit<
  PopOverMenuProps,
  'menu' | 'buttonProps'
> & {
  icon?: JSX.Element;
};

export default RequestPermissionsControl;
