import {
  useContentShareState,
  useFeaturedTileState,
  useLocalVideo,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { BaseProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import React from 'react';
import { useVideoGridLayout } from '../../../providers/VideoGridLayoutProvider';
import { FeaturedRemoteVideos } from '../FeaturedRemoteVideos';
import LocalVideo from '../LocalVideo';
import NamedContentShare from '../NamedContentShare';
import { RemoteVideos } from '../RemoteVideos';
import VideoGrid from '../VideoGrid';

const fluidStyles = `
  height: 100%;
  width: 100%;
`;

const staticStyles = `
  display: flex;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 20vw;
  max-height: 30vh;
  height: auto;

  video {
    position: static;
  }
`;
interface Props extends BaseProps {
  noRemoteVideoView?: React.ReactNode;
}

export const VideoTileGrid: React.FC<Props> = ({
  noRemoteVideoView,
  ...rest
}) => {
  const { tileId: featureTileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { isVideoEnabled } = useLocalVideo();

  const { layout } = useVideoGridLayout();
  const featured =
    (layout === 'featured' && !!featureTileId) || !!contentTileId;
  const remoteSize = tiles.length + (contentTileId ? 1 : 0);
  const gridSize =
    remoteSize > 1 && isVideoEnabled ? remoteSize + 1 : remoteSize;

  return (
    <VideoGrid {...rest} size={gridSize} layout={featured ? 'featured' : null}>
      <NamedContentShare />
      {layout === 'featured' ? <FeaturedRemoteVideos /> : <RemoteVideos />}
      <LocalVideo
        nameplate=""
        css={gridSize > 1 ? fluidStyles : staticStyles}
      />
      {remoteSize === 0 && noRemoteVideoView}
    </VideoGrid>
  );
};

export default VideoTileGrid;
