import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../constants/routes';
import { useJoinMeeting } from './JoinMeetingProvider';

export type NavigationContextType = {
  showRoster: boolean;
  showChat: boolean;
  showSettings: boolean;
  toggleRoster: () => void;
  toggleChat: () => void;
  toggleSettings: () => void;
  closeChat: () => void;
  closeRoster: () => void;
  closeSettings: () => void;
};

type Props = {
  children: ReactNode;
};

const NavigationContext = React.createContext<NavigationContextType | null>(
  null
);

const isDesktop = () => {
  return window.innerWidth > 768;
};

const NavigationProvider = ({ children }: Props) => {
  const [showRoster, setShowRoster] = useState(() => isDesktop());
  const [showChat, setShowChat] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const isDesktopView = useRef(isDesktop());

  const location = useLocation();
  const meetingManager = useMeetingManager();
  const { isOneOnOneMeeting } = useJoinMeeting();

  useEffect(() => {
    if (location.pathname.includes(routes.MEETING)) {
      return () => {
        meetingManager.leave();
      };
    }
  }, [location.pathname, meetingManager]);

  useEffect(() => {
    if (isOneOnOneMeeting) {
      setShowRoster(false);
      return;
    }
    const handler = () => {
      const isResizeDesktop = isDesktop();
      if (isDesktopView.current === isResizeDesktop) {
        return;
      }

      isDesktopView.current = isResizeDesktop;

      if (!isResizeDesktop) {
        setShowRoster(false);
        setShowChat(false);
        setShowSettings(false);
      }
    };

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [isOneOnOneMeeting]);

  const closeChat = (): void => {
    setShowChat(false);
  };

  const closeRoster = (): void => {
    setShowRoster(false);
  };

  const closeSettings = (): void => {
    setShowSettings(false);
  };

  const toggleChat = () => {
    closeRoster();
    closeSettings();
    setShowChat(!showChat);
  };

  const toggleRoster = (): void => {
    closeChat();
    closeSettings();
    setShowRoster(!showRoster);
  };

  const toggleSettings = (): void => {
    closeChat();
    closeRoster();
    setShowSettings(!showSettings);
  };

  const providerValue = {
    showRoster,
    showChat,
    showSettings,
    toggleRoster,
    toggleChat,
    toggleSettings,
    closeRoster,
    closeChat,
    closeSettings,
  };
  return (
    <NavigationContext.Provider value={providerValue}>
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw Error('Use useNavigation in NavigationProvider');
  }
  return context;
};

export { NavigationProvider, useNavigation };
