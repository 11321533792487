interface URLs {
  handRaiseWSSURL: string;
  messagingWSSURL: string;
  baseURL: string;
  frontendURL: string;
  broadcasts: {
    HLS: string;
    DASH: string;
    MSS: string;
    CMAF: string;
  };
}

interface AppVersion {
  gitCommit: string;
  buildId: string;
  appVersion: string;
  buildDate: string;
}

const appVersion = {
  gitCommit: process.env.REACT_APP_GIT_COMMIT ?? '',
  buildId: process.env.REACT_APP_BUILD_ID ?? '',
  appVersion: '0.1',
  buildDate: process.env.REACT_APP_BUILD_DATE ?? ''
}

const defaultURLs = {
  baseURL: process.env.REACT_APP_API_HOST ?? '',
  frontendURL: process.env.REACT_APP_FRONTEND_HOST ?? '',
  handRaiseWSSURL: '',
  messagingWSSURL: '',
  broadcasts: {
    DASH: '',
    MSS: '',
    CMAF: '',
    HLS:
      'https://usher.ttvnw.net/api/lvs/hls/lvs.693991300569.ross-stream.m3u8?allow_source=true',
  },
};

// Our deployment infra allows us to substitute strings wrapped in ${} with
// parameters from our CloudFormation template. This checks that the
// value was successfully substituted.
function isSubstitutedURL(sub: any) {
  return typeof sub === 'string' && sub[0] !== '$';
}

function isURLs(json: any): json is URLs {
  return (
    json &&
    isSubstitutedURL(json['handRaiseWSSURL']) &&
    isSubstitutedURL(json['messagingWSSURL']) &&
    isSubstitutedURL(json['baseURL']) &&
    isSubstitutedURL(json['frontendURL'])

  );
}

export function getURLs(): URLs {
  const deployedURLs = (window as any).liveEventsURLs;
  return (isURLs(deployedURLs) && deployedURLs) || defaultURLs;
}

export function getBaseURL(): string {
  return getURLs().baseURL;
}

export function getFrontendURL(): string {
  return getURLs().frontendURL;
}

export function getLiveEventsURL(): string {
  return `${getURLs().baseURL}`;
}

export function getHandRaiseWSSURL(): string {
  return '';
  // return getURLs().handRaiseWSSURL;
}

export function getMessagingWSSURL(): string {
  return '';
  // return getURLs().messagingWSSURL;
}

export function getAppVersion(): AppVersion {
  return appVersion;
}