import styled from 'styled-components';

interface StyledProps {}

export const StyledMeetingSettings = styled.div<StyledProps>`
  position: relative;
  width: 18.5rem;
  height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;
  background-color: ${(props) => props.theme.chat.bgd};
  box-shadow: 1rem 1rem 3.75rem 0 rgba(0, 0, 0, 0.1);
  border-left: 0.0625rem solid ${(props) => props.theme.colors.greys.grey30};
  border-right: 0.0625rem solid ${(props) => props.theme.colors.greys.grey30};
`;

export const StyledSettingsContent = styled.div`
  padding: 0.5rem;

  width: min(25rem, 100%);
  margin: auto;

  .inactive button {
    background-color: ${(props) => props.theme.colors.primary.main};
    border-radius: 0.25rem;
  }
`;
