import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalHeader,
} from 'amazon-chime-sdk-component-library-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card';
import Spinner from '../../components/Spinner';
import routes from '../../constants/routes';
import useTranslate from '../../hooks/useTranslate';
import { getErrorContext } from '../../providers/ErrorProvider';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import DevicePermissionPrompt from '../DevicePermissionPrompt';

const MeetingJoin: React.FC = () => {
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const history = useHistory();
  const translate = useTranslate();
  const { isLoading, joinInfo } = useJoinMeeting();

  const closeError = (): void => {
    updateErrorMessage('');
  };

  const handleJoinMeeting = useCallback(() => {
    history.replace(routes.DEVICE);
  }, [history]);

  useEffect(() => {
    if (joinInfo?.meeting) {
      handleJoinMeeting();
    }
  }, [joinInfo, handleJoinMeeting]);

  return (
    <form>
      <Flex container layout="fill-space-centered">
        <Heading tag="h1" level={2} css="margin-bottom: 1rem">
          {translate('MeetingJoin.Info')}
        </Heading>
      </Flex>
      <Flex
        container
        layout="fill-space-centered"
        style={{ marginTop: '2.5rem' }}
      >
        {isLoading && !joinInfo?.meeting ? <Spinner /> : <> </>}
      </Flex>
      {errorMessage && (
        <Modal size="md" onClose={closeError}>
          <ModalHeader title={translate('MeetingJoin.ErrorHeader')} />
          <ModalBody>
            <Card
              title={translate('MeetingJoin.ErrorTitle')}
              description={translate('MeetingJoin.ErrorDescription')}
              smallText={errorMessage}
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  );
};

export default MeetingJoin;
