import {
  useAudioVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserRights } from '../types/UserRights';
import { getPermissions } from '../utils/api';
import { getErrorContext } from './ErrorProvider';
import { useEventContext } from './EventProvider';
import BackendEvents from '../enums/BackendEvents';
import { DataMessage } from 'amazon-chime-sdk-js';
import { UpdateRightsMessageType } from '../types/MeetingMessage';

interface LoginUser {
  description: string;
  firstname: string;
  fullname: string;
  id: string;
  internalid: string;
  lastname: string;
  rights: UserRights[];
  url: string;
}

interface RightsContextValue {
  validateAction: (right: UserRights, action: () => void) => void;
  hasRight: (right: UserRights) => boolean;
  loginUser: LoginUser | null;
  mayStartMeeting: boolean;
}

const RightsContext = createContext<RightsContextValue | null>(null);

const RightsProvider: React.FC = ({ children }) => {
  // const { openPromotionModal, showPromotionModal } = usePromotedAttendees();
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const { updateErrorMessage } = useContext(getErrorContext());
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);
  const [rights, setRights] = useState<Set<UserRights>>(new Set());
  const localAttendeeId = meetingManager.meetingSessionConfiguration?.credentials?.attendeeId;

  const event = useEventContext();

  const hasRight = useCallback(
    (right: UserRights) => {
      return rights.has(right);
    },
    [rights]
  );

  const validateAction = useCallback(
    (right: UserRights, action: () => void): void => {
      if (hasRight(right)) {
        action();
      } else {
        console.log('Please give rights');
        //   openPromotionModal();
        // showPromotionModal && <PromotionModal />;
      }
    },
    [
      hasRight,
      //  openPromotionModal, showPromotionModal
    ]
  );

  useEffect(() => {
    if (!audioVideo || !localAttendeeId) {
      return;
    }

    const updateRightsCallback = (data: DataMessage) => {
      const changedRightsEvent: UpdateRightsMessageType = data.json();
      const { revoked, granted, attendeeId } = changedRightsEvent;
      if (attendeeId === localAttendeeId) {
        setRights((rights) => {
          const updatedRights = [...rights, ...granted];
          const filteredRights = updatedRights.filter(
            (right) => !revoked.includes(right)
          );
          return new Set(filteredRights);
        });
      }
    };

    audioVideo?.realtimeSubscribeToReceiveDataMessage(
      BackendEvents.UpdateRights,
      updateRightsCallback
    );

    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
        BackendEvents.UpdateRights
      );
    };
  }, [audioVideo, localAttendeeId]);

  useEffect(() => {
    const start = async () => {
      if (event.userId === '' || loginUser !== null) {
        return;
      }
      const response = await getPermissions(event);
      const responseData = response.data.data;
      if (responseData !== null) {
        setLoginUser(response.data.data);
        setRights(new Set(response.data.data.rights));
      } else {
        updateErrorMessage('External user data not found');
      }
    };
    start();
  }, [event, loginUser, updateErrorMessage]);

  const value = useMemo(
    () => ({
      hasRight,
      validateAction,
      loginUser,
      mayStartMeeting:
        loginUser?.rights.includes(UserRights.ALLOW_START_MEETING) || false,
    }),
    [hasRight, validateAction, loginUser]
  );

  return (
    <RightsContext.Provider value={value}>{children}</RightsContext.Provider>
  );
};

function useRights(): RightsContextValue {
  const state = useContext(RightsContext);

  if (!state) {
    throw new Error('useRights must be used within RightsProvider');
  }

  return state;
}

export { RightsProvider, useRights };
