import { IconButton, Remove } from 'amazon-chime-sdk-component-library-react';
import React, { ReactNode } from 'react';
import { BaseProps } from '../../Base';
import { StyledChatHeader } from './Styled';

interface ChatHeaderProps extends BaseProps {
  title: string;
  badge?: number;
  onClose?: () => void;
  children?: ReactNode | ReactNode[];
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  title,
  badge,
  onClose,
  children,
  ...rest
}) => {
  return (
    <StyledChatHeader {...rest}>
      <div className="ch-title">{title}</div>
      <div className="ch-buttons">
        {children}
        {onClose && <IconButton onClick={onClose} label="close" icon={<Remove />} />}
      </div>
    </StyledChatHeader>
  );
};

export default ChatHeader;
