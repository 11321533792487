import React from 'react';
import {
  CameraSelection,
  Heading,
  Label,
  QualitySelection,
} from 'amazon-chime-sdk-component-library-react';
import useTranslate from '../../../hooks/useTranslate';
import { BaseProps } from '../../Base';
import PreviewVideo from '../../Videos/PreviewVideo';
import { StyledInputGroup, title } from '../Styled';
import { VideoBackgroundSelection } from './VideoBackgroundSelection';

export interface DeviceSelectionProps extends BaseProps {
  noMargin?: boolean;
  withBlurControl?: boolean;
}

const CameraDevices = (props: DeviceSelectionProps) => {
  const translate = useTranslate();
  const { noMargin } = props;

  return (
    <div>
      <Heading tag="h2" level={6} css={noMargin ? undefined : title}>
        {translate('CameraDevices.Header')}
      </Heading>
      <StyledInputGroup noMargin={noMargin}>
        <CameraSelection label={translate('CameraDevices.SelectionLabel')} />
      </StyledInputGroup>
      <StyledInputGroup noMargin={noMargin}>
        <QualitySelection label={translate('CameraDevices.QualityLabel')} />
      </StyledInputGroup>
      <Label
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '.5rem',
        }}
      >
        {translate('CameraDevices.PreviewLabel')}
      </Label>
      <PreviewVideo />
      <VideoBackgroundSelection />
    </div>
  );
};

export default CameraDevices;
