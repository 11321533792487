import React, { FC } from 'react';

import { BaseProps } from '../Base';
import { StyledBadge } from './Styled';

export interface BadgeProps extends BaseProps {
  value: string | number | JSX.Element;
  status?: 'default' | 'alert';
}

export const Badge: FC<BadgeProps> = ({
  value,
  status = 'default',
  className,
  tag,
  ...rest
}) => {
  return (
    <StyledBadge
      className={className || ''}
      as={tag}
      status={status}
      value={value}
      data-testid="badge"
      {...rest}
    >
      {value}
    </StyledBadge>
  );
};

export default Badge;
