import defaultTheme from './default';
import { hexTorgba } from '../utils/style';

export const colors = {
  primary: {
    lightest: 'hsl(0deg, 100%, 65%)',
    lighter: 'hsl(0deg, 100%, 60%)',
    light: 'hsl(0deg, 100%, 55%)',
    main: 'hsl(0deg, 100%, 50%)',
    dark: 'hsl(0deg, 100%, 45%)',
    darker: 'hsl(0deg, 100%, 40%)',
    darkest: 'hsl(0deg, 100%, 35%)',
  },

  secondary: {
    lightest: 'hsl(0deg, 0%, 90%)',
    lighter: 'hsl(0deg, 0%, 85%)',
    light: 'hsl(0deg, 0%, 80%)',
    main: 'hsl(0deg, 0%, 75%)',
    dark: 'hsl(0deg, 0%, 70%)',
    darker: 'hsl(0deg, 0%, 65%)',
    darkest: 'hsl(0deg, 0%, 60%)',
  },

  error: {
    lightest: '#FCF7F6',
    lighter: '#F5DDD5',
    light: '#FF927C',
    primary: '#C52000',
    dark: '#9E3319',
    darker: '#89301A',
    darkest: '#791800',
  },

  success: {
    lightest: '#EBF1EA',
    lighter: '#CEE0C8',
    light: '#50CD49',
    primary: '#067000',
    dark: '#305D1D',
    darker: '#2C511D',
    darkest: '#184206',
  },

  info: {
    lightest: '#DADFE7',
    lighter: '#C4CCD9',
    light: '#418AFD',
    primary: '#2555A0',
    dark: '#264A82',
    darker: '#243F6B',
    darkest: '#123366',
  },

  warning: {
    lightest: '#C4C0C0', // app text color neede after bug
    lighter: '#F7E79E',
    light: '#F9DC60',
    primary: '#F9CC09',
    dark: '#665A2A',
    darker: '#584E26',
    darkest: '#ffffff', // button text
  },

  greys: {
    black: '#000000',
    grey100: '#1b1c20',
    grey90: '#2e2f34',
    grey80: '#3f4149',
    grey70: '#50545e',
    grey60: '#616672',
    grey50: '#7d818b',
    grey40: '#989da5',
    grey30: '#d4d5d8',
    grey20: '#e4e9f2',
    grey10: '#f0f1f2',
    white: '#ffffff',
  },

  appcolors: {
    appbgd: '#4A4A4A',
    apptext: '#C4C0C0',
    primary: 'hsl(0deg, 100%, 50%)',
    primarydark: '#1A1A1A',
    rosterheader: '#7E7E7E',
    rostertext: '#A9B2B3',
    rosteritembg: '#C4C0C0',
    rosteritemtext: '#7E7E7E',
    black: '#000000',
    white: '#ffffff',
    warn: '#FF0000',
  },
};

const globalStyles = {
  bgd: colors.appcolors.appbgd,
  text: colors.appcolors.apptext,
  fontSize: defaultTheme.fontSizes.baseFontSize,
};

const shadows = {
  none: 'none',
  small: `0 0.09375rem 0.0625rem 0 ${hexTorgba(colors.greys.grey100, 0.15)}`,
  medium: `0 0.5rem 0.85rem 0 ${hexTorgba(colors.greys.black, 0.15)}`,
  large: `0 0.75rem 1.875rem 0 ${hexTorgba(colors.greys.black, 0.15)}`,
};

const buttons = {
  primary: {
    shadow: shadows.small,
    static: {
      bgd: colors.primary.main,
      border: `0.03125rem solid ${colors.appcolors.primarydark}`,
      text: colors.greys.white,
    },
    hover: {
      bgd: colors.primary.dark,
      border: `0.03125rem solid ${colors.appcolors.black}`,
      text: colors.greys.white,
    },
    focus: {
      bgd: colors.primary.main,
      border: `0.03125rem solid ${colors.appcolors.primary}`,
      text: colors.greys.white,
    },
    active: {
      bgd: colors.primary.main,
      border: `0.03125rem solid ${colors.appcolors.primary}`,
      text: colors.greys.white,
    },
    selected: {
      bgd: colors.primary.main,
      border: `0.03125rem solid ${colors.appcolors.primary}`,
      text: colors.greys.white,
    },
    disabled: {
      bgd: colors.primary.main,
      border: `0.03125rem solid ${colors.greys.grey10}`,
      text: colors.greys.grey40,
    },
  },

  secondary: {
    shadow: shadows.small,
    static: {
      bgd: colors.secondary.main,
      border: `0.03125rem solid ${colors.greys.grey30}`,
      text: colors.greys.grey70,
    },
    hover: {
      bgd: colors.secondary.dark,
      border: `0.03125rem solid ${colors.greys.grey30}`,
      text: colors.greys.grey70,
    },
    focus: {
      bgd: colors.secondary.main,
      border: `0.03125rem solid ${colors.greys.grey30}`,
      text: colors.greys.grey70,
    },
    active: {
      bgd: colors.secondary.main,
      border: `0.03125rem solid ${colors.greys.grey40}`,
      text: colors.greys.grey70,
    },
    selected: {
      bgd: colors.secondary.main,
      border: `0.03125rem solid ${colors.greys.grey30}`,
      text: colors.greys.grey70,
    },
    disabled: {
      bgd: colors.secondary.main,
      border: `0.03125rem solid ${colors.greys.grey10}`,
      text: colors.greys.grey40,
    },
  },

  icon: {
    shadow: shadows.none,
    static: {
      bgd: 'transparent',
      border: 'none',
      text: colors.appcolors.white,
    },
    hover: {
      bgd: 'transparent',
      border: 'none',
      text: colors.appcolors.white,
    },
    focus: {
      bgd: 'transparent',
      border: 'none',
      text: colors.appcolors.white,
    },
    active: {
      bgd: 'transparent',
      border: 'none',
      text: colors.appcolors.white,
    },
    selected: {
      bgd: 'transparent',
      border: 'none',
      text: colors.appcolors.white,
    },
    disabled: {
      bgd: 'transparent',
      border: `none`,
      text: colors.greys.grey10,
    },
  },

  warn: {
    shadow: shadows.none,
    static: {
      bgd: colors.appcolors.warn,
      border: 'none',
      text: colors.appcolors.white,
    },
    hover: {
      bgd: colors.appcolors.warn,
      border: 'none',
      text: colors.appcolors.white,
    },
    focus: {
      bgd: colors.appcolors.warn,
      border: 'none',
      text: colors.appcolors.white,
    },
    active: {
      bgd: colors.appcolors.warn,
      border: 'none',
      text: colors.appcolors.white,
    },
    selected: {
      bgd: colors.appcolors.warn,
      border: 'none',
      text: colors.appcolors.white,
    },
  },
};

const inputs = {
  bgd: colors.appcolors.appbgd,
  border: `0.03125rem solid ${colors.greys.grey30}`,
  borderRadius: defaultTheme.radii.default,
  fontColor: colors.greys.grey20,
  placeholder: colors.greys.grey40,
  shadow: `0 0.0625rem 0.0625rem 0 ${hexTorgba(colors.greys.black, 0.1)}`,
  clearBg: colors.greys.grey50,

  focus: {
    bgd: colors.greys.white,
    border: `solid 0.03125rem ${colors.appcolors.primary}`,
    shadow: `0 0 0 0.125rem ${colors.appcolors.primary}`,
  },

  error: {
    border: `0.03125rem solid ${colors.error.dark}`,
    fontColor: colors.error.primary,
    shadow: `0 0 0 0.125rem ${colors.error.light}`,
  },

  checked: {
    bgd: colors.greys.white,
    border: `solid 0.03125rem ${colors.greys.white}`,
    fontColor: colors.greys.black,
    shadow: `0 0.03125rem 0.03125rem 0 ${hexTorgba(colors.primary.dark, 0.7)}`,
  },
};

const modal = {
  bgd: colors.greys.white,
  text: colors.greys.grey80,
  wrapperBgd: hexTorgba(colors.greys.grey50, 0.9),
  titleSize: defaultTheme.fontSizes.h5.fontSize,
  titleWeight: 'normal',
  shadow: shadows.large,
  border: colors.greys.grey30,
};

const popOver = {
  menuBgd: colors.appcolors.appbgd,
  menuBorder: `0.5px solid ${colors.greys.grey20}`,
  shadow: shadows.large,
  itemBgd: colors.appcolors.warn,
  itemText: colors.appcolors.black,
  titleText: colors.appcolors.black,
  active: {
    itemBgd: colors.primary.dark,
    itemText: colors.greys.white,
  },
  separator: `${hexTorgba(colors.greys.grey40, 0.3)}`,
};

const notification = {
  shadow: shadows.large,
  error: {
    text: colors.error.lightest,
    closeButton: {
      text: colors.error.lighter,
      hover: {
        bgd: colors.error.lighter,
        text: colors.error.dark,
      },
      active: {
        bgd: colors.error.lightest,
        text: colors.error.darker,
      },
    },
  },

  success: {
    text: colors.success.lightest,
    closeButton: {
      text: colors.success.lighter,
      hover: {
        bgd: colors.success.lighter,
        text: colors.success.dark,
      },
      active: {
        bgd: colors.success.lightest,
        text: colors.success.darker,
      },
    },
  },

  info: {
    text: colors.info.lightest,
    closeButton: {
      text: colors.info.lighter,
      hover: {
        bgd: colors.info.lighter,
        text: colors.info.dark,
      },
      active: {
        bgd: colors.info.lightest,
        text: colors.info.darker,
      },
    },
  },

  warning: {
    text: colors.warning.darker,
    closeButton: {
      text: colors.warning.dark,
      hover: {
        bgd: colors.warning.dark,
        text: colors.greys.white,
      },
      active: {
        bgd: colors.warning.darker,
        text: colors.greys.white,
      },
    },
  },
};

const links = {
  fontColor: colors.primary.main,
  fontColorHover: colors.primary.dark,
  fontColorActive: colors.primary.darker,
  fontColorVisited: colors.primary.darkest,
};

const controlBar = {
  text: colors.appcolors.white,
  shadow: shadows.large,
  bgd: colors.appcolors.primarydark,
  border: `0.03125rem solid ${colors.greys.grey20}`,
  opacity: 0.95,
  selected: {
    text: buttons.primary.selected.text,
    bgd: buttons.primary.selected.bgd,
  },
};

const roster = {
  title: colors.greys.grey100,
  primaryText: colors.greys.white,
  secondaryText: colors.greys.white,
  headerBorder: colors.greys.grey40,
  containerBorder: colors.greys.grey30,
  bgd: colors.appcolors.appbgd,
  fgd: colors.appcolors.appbgd,
  shadow: shadows.large,
  maxWidth: '18.5rem',
};

const navbar = {
  text: colors.greys.grey80,
  bgd: colors.appcolors.primarydark,
  headerBorder: colors.greys.grey40,
  wrapperBgd: hexTorgba(colors.greys.grey50, 0.9),
};

const videoGrid = {
  bgd: colors.appcolors.appbgd,
};

const chatBubble = {
  incoming: {
    bgd: colors.greys.grey70,
    fontColor: colors.greys.white,
  },
  outgoing: {
    bgd: colors.greys.grey50,
    fontColor: colors.greys.white,
  },
  container: {
    fontColor: colors.greys.grey70,
    bgd: colors.greys.grey10,
  },
};

const chat = {
  bgd: colors.appcolors.appbgd,
};

const chatDateHeader = {
  bgd: colors.greys.grey60,
  fontColor: colors.greys.white,
};

export const darkTheme = {
  name: 'Dark Theme',
  buttons,
  colors,
  globalStyles,
  links,
  shadows,
  inputs,
  modal,
  popOver,
  notification,
  controlBar,
  roster,
  navbar,
  videoGrid,
  chatBubble,
  chatDateHeader,
  chat,
  ...defaultTheme,
};

export default darkTheme;
