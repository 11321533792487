import React from 'react';
import { BaseProps } from '../../components/Base';
import CameraDevices from '../../components/DeviceSelection/CameraDevices';
import LayoutSelection from '../../components/DeviceSelection/LayoutSelection';
import MicrophoneDevices from '../../components/DeviceSelection/MicrophoneDevices';
import SettingsHeader from '../../components/DeviceSelection/SettingsHeader';
import SpeakerDevices from '../../components/DeviceSelection/SpeakerDevices';
import { ThemeSelection } from '../../components/ThemeSelection/ThemeSelection';
import useTranslate from '../../hooks/useTranslate';
import { useNavigation } from '../../providers/NavigationProvider';
import AudioOutputControl from '../MeetingControls/AudioOutputControl';
import { StyledMeetingSettings, StyledSettingsContent } from './Styled';

interface Props extends BaseProps {}

const MeetingsSettings: React.FC<Props> = () => {
  const { closeSettings } = useNavigation();
  const translate = useTranslate();

  return (
    <StyledMeetingSettings className="settings">
      <SettingsHeader title="Setup" onClose={() => closeSettings()} />
      <StyledSettingsContent>
        <MicrophoneDevices noMargin />
        <SpeakerDevices />
        <legend>{translate('MeetingSettings.AudioOutputControl')}</legend>
        <AudioOutputControl />
        <LayoutSelection />
        <ThemeSelection />
        <CameraDevices noMargin />
      </StyledSettingsContent>
    </StyledMeetingSettings>
  );
};

export default MeetingsSettings;
