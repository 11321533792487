import {
  Sound,
  useLocalAudioOutput,
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';

interface Props {
  label?: string;
}

const AudioOutputControl: React.FC<Props> = () => {
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();

  return (
    <>
      <ControlBarButton
        className={!isAudioOn ? 'inactive' : undefined}
        icon={<Sound disabled={!isAudioOn} />}
        onClick={toggleAudio}
      />
    </>
  );
};

export default AudioOutputControl;
