import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChatBubble from '../../components/Chat/ChatBubble';
import ChatHeader from '../../components/Chat/ChatHeader';
import ChatInput from '../../components/Chat/ChatInput';
import useTranslate from '../../hooks/useTranslate';
import { ChatMessageType, useChatState } from '../../providers/ChatProvider';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import { useNavigation } from '../../providers/NavigationProvider';
import { StyledChatMessageContainer, StyledMeetingChat } from './Styled';

const MeetingChat = () => {
  const { closeChat } = useNavigation();
  const { chatMessages } = useChatState();
  const translate = useTranslate();
  const { isOneOnOneMeeting } = useJoinMeeting();

  /* eslint-disable */
  const URL_REGEX =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
  /* eslint-enable */

  const renderText = (text: string) => {
    const a = text.split(' ').map((part) =>
      URL_REGEX.test(part) ? (
        <>
          <a href={addMissingProtocol(part)} rel="noreferrer" target="_blank">
            {part}
          </a>
          <br />
        </>
      ) : (
        part + ' '
      )
    );
    return a;
  };

  const addMissingProtocol = (text: string) => {
    if (!text.includes('http')) {
      return `http://${text}`;
    } else {
      return text;
    }
  };

  const messageItems = chatMessages.map((chatMessage: ChatMessageType) => {
    return (
      <ChatBubble
        key={chatMessage.timeStamp}
        variant={chatMessage.attendeeId === 'local' ? 'outgoing' : 'incoming'}
        senderName={chatMessage.name}
        content={renderText(chatMessage.message)}
        css="margin: 1rem;"
      />
    );
  });

  const [scrollViewNearBottom, setScrollViewNearBottom] = useState(true);
  const chatElement = useRef<HTMLDivElement>(null);

  const handleChatMessageAdded = useCallback(
    (e) => {
      const { currentTarget: target } = e;
      if (target instanceof HTMLElement && scrollViewNearBottom) {
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      }
    },
    [scrollViewNearBottom]
  );

  const handleScroll = useCallback((e) => {
    const { currentTarget: target } = e;
    if (target instanceof HTMLElement) {
      const autoScrollThreshold = 250; //px
      const position = target.scrollTop + target.offsetHeight;
      const scrollHeight = target.scrollHeight;
      const nearBottom = position > scrollHeight - autoScrollThreshold;
      setScrollViewNearBottom(nearBottom);
    }
  }, []);

  useEffect(() => {
    const currentElement = chatElement?.current;
    currentElement?.addEventListener('DOMNodeInserted', handleChatMessageAdded);

    return () => {
      currentElement?.removeEventListener(
        'DOMNodeInserted',
        handleChatMessageAdded
      );
    };
  }, [handleChatMessageAdded]);

  useEffect(() => {
    const currentElement = chatElement?.current;
    currentElement?.addEventListener('scroll', handleScroll);

    return () => {
      currentElement?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const chat = chatElement.current;
    chat?.scroll({ top: chat.scrollHeight, behavior: 'smooth' });
  }, []);

  return (
    <StyledMeetingChat
      className="chat"
      ref={chatElement}
      isOneOnOneMeeting={isOneOnOneMeeting}
    >
      <ChatHeader
        title={translate('MeetingChat.Title')}
        onClose={() => closeChat()}
      />
      <StyledChatMessageContainer>{messageItems}</StyledChatMessageContainer>
      <ChatInput />
    </StyledMeetingChat>
  );
};

export default MeetingChat;
