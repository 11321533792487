import {
  Modal,
  ModalBody,
  ModalHeader
} from 'amazon-chime-sdk-component-library-react';
import Card from '../components/Card';
import { DeviceLabelTriggerStatus } from '../enums';
import useDeviceLabelTriggerStatus from '../hooks/useDeviceLabelTriggerStatus';
import useTranslate from '../hooks/useTranslate';

const DevicePermissionPrompt = () => {
  const permission = useDeviceLabelTriggerStatus();
  const translate = useTranslate();

  return permission === DeviceLabelTriggerStatus.IN_PROGRESS ? (
    <Modal
      size="md"
      onClose={(): void => console.log('Permission prompt closed')}
      rootId="device-permission-modal-root"
    >
      <ModalHeader
        title={translate('DevicePermissionPrompt.Header')}
        displayClose={false}
      />
      <ModalBody>
        <Card
          title={translate('DevicePermissionPrompt.BodyTitle')}
          description={
            <>
              <p>{translate('DevicePermissionPrompt.BodyText1')}</p>
              <p>
                {translate('DevicePermissionPrompt.BodyText2')}
                <strong>{translate('DevicePermissionPrompt.BodyText3')}</strong>
                .
              </p>
            </>
          }
        />
      </ModalBody>
    </Modal>
  ) : null;
};

export default DevicePermissionPrompt;
