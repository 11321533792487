import styled from 'styled-components';

export const StyledNavbarBadgeItem = styled.div`
  position: relative; 
  display: flex;
  align-items: center;
  height: 3rem;
  margin: 0;

  .badge {
    position: absolute;
    left: 1.75rem;
    top: 0.5rem;
  }

  ${({ theme }) => theme.mediaQueries.max.xs} {
    margin-left: 0.5rem;

    .ch-navigation-bar-item-label {
      margin-left: 0.625rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.min.md} {
    .ch-navigation-bar-item-label {
      display: none;
    }
    margin-left: 0.5rem;
  }
`;

export const StyledNavigationControls = styled.div`
  display: flex;
  align-items: center;
`;
