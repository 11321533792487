import React, { ReactNode, useContext, useState } from 'react';

type Props = {
  children: ReactNode;
};

interface AppStateValue {
  meetingId: string;
  region: string;
  setAppMeetingInfo: (meetingId: string, region: string) => void;
}

const AppStateContext = React.createContext<AppStateValue | null>(null);

export function useAppState(): AppStateValue {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return state;
}

const query = new URLSearchParams(window.location.search);

export function AppStateProvider({ children }: Props) {
  const [meetingId, setMeeting] = useState(query.get('meeting') || '');
  const [region, setRegion] = useState(query.get('region') || '');

  const setAppMeetingInfo = (meetingId: string, region: string) => {
    setRegion(region);
    setMeeting(meetingId);
  };

  const providerValue = {
    meetingId,
    region,
    setAppMeetingInfo,
  };

  return (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  );
}
