import { FormField, Select } from 'amazon-chime-sdk-component-library-react';
import React, { ChangeEvent } from 'react';
import { useVideoGridLayout } from '../../../providers/VideoGridLayoutProvider';
import { Layout } from '../../Videos/VideoGrid';

interface Props {
  label?: string;
}

export const LayoutSelection: React.FC<Props> = ({
  label = 'Switch layout',
}) => {
  const { setGridLayout, setFeaturedLayout, layout } = useVideoGridLayout();

  const options = [
    { value: 'featured', label: 'Featured' },
    { value: 'standard', label: 'Grid' },
  ];

  function selectLayout(e: ChangeEvent<HTMLSelectElement>) {
    const layout: Layout = e.target.value as Layout;

    if (layout === 'featured') {
      setFeaturedLayout();
    } else {
      setGridLayout();
    }
  }

  return (
    <FormField
      field={Select}
      options={options}
      onChange={selectLayout}
      value={layout || ''}
      label={label}
    />
  );
};

export default LayoutSelection;
