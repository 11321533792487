import { Flex, Heading } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const MeetingEnd: React.FC = () => {
  const translate = useTranslate();

  return (
    <form>
      <Flex container layout="fill-space-centered">
        <Heading tag="h1" level={2} css="margin-bottom: 1rem">
          {translate('MeetingEnd.Header')}
        </Heading>
      </Flex>
      <Flex container layout="fill-space-centered">
        <Heading tag="h2" level={6} css="margin-bottom: 1rem">
          {translate('MeetingEnd.Info')}
        </Heading>
      </Flex>
      <Flex
        container
        layout="fill-space-centered"
        style={{ marginTop: '2.5rem' }}
      ></Flex>
    </form>
  );
};

export default MeetingEnd;
