const routes = {
  'HOME': '/',
  'DEVICE': '/device',
  'MEETING': '/meeting',
  'ONEONONE': '/oneonone',
  'ENDED': '/ended',
  'LEAVE': '/leave',
  'KICKED': '/kicked',
}

export default routes;
