import { Heading } from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import DeviceSelection from '../../components/DeviceSelection';
import MeetingHeader from '../../containers/MeetingHeader/MeetingHeader';
import JoinMeetingDetails from '../../containers/MeetingJoinDetails';
import useTranslate from '../../hooks/useTranslate';
import { useHeartbeat } from '../../providers/HeartbeatProvider';
import { StyledLayout } from './Styled';

const DeviceSetup: React.FC = () => {
  const translate = useTranslate();
  const { switchHeartbeatStatus } = useHeartbeat();

  useEffect(() => {
    switchHeartbeatStatus('setup');
  }, [switchHeartbeatStatus]);

  return (
    <>
      <MeetingHeader noControls />
      <StyledLayout>
        <Heading tag="h1" level={3} css="align-self: flex-center">
          {translate('DeviceSetup.Header')}
        </Heading>
        <JoinMeetingDetails />
        <DeviceSelection />
      </StyledLayout>
    </>
  );
};

export default DeviceSetup;
