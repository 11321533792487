import { useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';import { DeviceLabelTriggerStatus } from '../enums';

export default function useDeviceLabelTriggerStatus() {
  const meetingManager = useMeetingManager();
  const [permission, setPermission] = useState<string>(
    DeviceLabelTriggerStatus.UNSET
  );

  useEffect(() => {
    const callback = (updatedPermission: string): void => {
      setPermission(updatedPermission);
    };
    meetingManager.subscribeToDeviceLabelTriggerStatus(callback);
    return () => {
      meetingManager.unsubscribeFromDeviceLabelTriggerStatus(callback);
    };
  }, [meetingManager]);

  return permission;
}
