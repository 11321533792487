import { Flex, Heading } from 'amazon-chime-sdk-component-library-react';
import React, { useEffect } from 'react';
import useTranslate from '../../hooks/useTranslate';
import { useHeartbeat } from '../../providers/HeartbeatProvider';
import { StyledLayout } from './Styled';

const MeetingLeft = () => {
  const { switchHeartbeatStatus } = useHeartbeat();
  const translate = useTranslate();

  useEffect(() => {
    switchHeartbeatStatus('left');
  }, [switchHeartbeatStatus]);

  return (
    <StyledLayout>
      <form>
        <Flex container layout="fill-space-centered">
          <Heading tag="h1" level={2} css="margin-bottom: 1rem">
            {translate('MeetingLeft.Header')}
          </Heading>
        </Flex>
        <Flex container layout="fill-space-centered">
          <Heading tag="h2" level={6} css="margin-bottom: 1rem">
            {translate('MeetingLeft.Info')}
          </Heading>
        </Flex>
        <Flex
          container
          layout="fill-space-centered"
          style={{ marginTop: '2.5rem' }}
        ></Flex>
      </form>{' '}
    </StyledLayout>
  );
};

export default MeetingLeft;
