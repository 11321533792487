import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RosterAttendeeType } from '../types/RosterAttendeeType';
import { useJoinMeeting } from './JoinMeetingProvider';
import { useRosterState } from './RosterProvider';

const emptyAttendee: RosterAttendeeType = {
  id: '',
  attendeeid: '',
  lastname: '',
  fullname: '',
  liveEventAttendeeId: '',
  events: [],
  role_attendee: false,
  role_moderator: false,
  role_speaker: false,
  order: 0,
  url: '',
  sessionid: '',
  hasRaisedHand: false,
  description: '',
  rights: new Set(),
  requestedRights: new Set(),
  joinon: 0,
};

interface ContextState {
  localAttendee: RosterAttendeeType;
}

const initialState: ContextState = {
  localAttendee: emptyAttendee,
};

const Context = createContext<ContextState>(initialState);

const LocalAttendeeProvider: React.FC = ({ children }) => {
  const [localAttendee, setLocalAttendee] =
    useState<RosterAttendeeType>(emptyAttendee);
  const { roster } = useRosterState();
  const { joinInfo } = useJoinMeeting();

  useEffect(() => {
    const localAttendeeId = joinInfo?.user.id as string;
    if (!roster[localAttendeeId]) {
      return;
    }
    setLocalAttendee(roster[localAttendeeId]);
  }, [joinInfo, roster, localAttendee]);

  const state = useMemo(
    () => ({
      localAttendee,
    }),
    [localAttendee]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

const useLocalAttendee = () => {
  const state = useContext(Context);

  if (!state) {
    throw new Error(
      'useLocalAttendee must be used within LocalAttendeeProvider'
    );
  }

  return state;
};

export { LocalAttendeeProvider, useLocalAttendee };
