import React from 'react';
import MeetingJoin from '../../containers/MeetingJoin';
import { StyledLayout } from './Styled';

const Home = () => (
  <StyledLayout>
    <MeetingJoin />
  </StyledLayout>
);

export default Home;
