import { useElementAspectRatio } from 'amazon-chime-sdk-component-library-react';
import React, { createContext, createRef, useContext } from 'react';
import { StyledGrid } from './Styled';

export type Layout = 'standard' | 'featured' | null;

export interface VideoGridProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  layout?: Layout;
}

interface GridContextState {
  usingGrid: boolean;
}

const gridData = { usingGrid: true };
const GridContext = createContext<GridContextState | null>(null);

export const VideoGrid: React.FC<VideoGridProps> = ({
  size,
  children,
  layout,
  ...rest
}) => {
  const gridEl = createRef<HTMLDivElement>();
  const ratio = useElementAspectRatio(gridEl);
  const gridSize =
    typeof size === 'number' ? size : React.Children.count(children);

  return (
    <GridContext.Provider value={gridData}>
      <StyledGrid
        ref={gridEl}
        {...rest}
        size={gridSize}
        ratio={ratio}
        featured={layout === 'featured'}
        data-testid="video-grid"
      >
        {children}
      </StyledGrid>
    </GridContext.Provider>
  );
};

export const useGridData = () => {
  const gridData = useContext(GridContext);

  return gridData;
};

export default VideoGrid;
