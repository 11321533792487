import React, { forwardRef, HTMLAttributes } from 'react';

import { BaseProps } from '../Base';
import { StyledButton } from './Styled';

export interface ButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'css'>,
    BaseProps {
  icon?: JSX.Element;
  label?: string;
  variant?: ButtonVariant;
  selected?: boolean;
  iconSize?: IconSize;
  disabled?: boolean;
}

export type IconSize = 'sm' | 'md' | 'lg';
export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'icon'
  | 'warn';

export const Button = forwardRef(
  (props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <StyledButton
      {...props}
      className={props.className || ''}
      as={props.tag}
      ref={ref}
      aria-label={props.label}
      data-testid="button"
      disabled={props.disabled}
    >
      {props.icon && (
        <span className="ch-icon" data-testid="button-icon">
          {props.icon}
        </span>
      )}
      {props.label && (
        <span className="ch-label" data-testid="button-label">
          {props.label}
        </span>
      )}
    </StyledButton>
  )
);

export default Button;
