import {
  Heading,
  MicSelection
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import useTranslate from '../../../hooks/useTranslate';
import { DeviceSelectionProps } from '../CameraDevices';
import { title } from '../Styled';

const MicrophoneDevices = (props: DeviceSelectionProps) => {
  const translate = useTranslate();
  const { noMargin } = props;

  return (
    <div>
      <Heading tag="h2" level={6} css={noMargin ? undefined : title}>
        {translate('MicrophoneDevices.AudioHeader')}
      </Heading>
      <MicSelection label={translate('MicrophoneDevices.AudioLabel')} />
      {/* <MicrophoneActivityPreview /> */}
    </div>
  );
};

export default MicrophoneDevices;
