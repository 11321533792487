import {
  Camera,
  HandRaise,
  Microphone,
  ScreenShare,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';
import { RosterCellProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Roster/RosterCell';
import React from 'react';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import { useLocalAttendee } from '../../providers/LocalAttendeeProvider';
import { useHandRaise } from '../../providers/MeetingMessagesProvider/HandRaiseProvider';
import { useRosterState } from '../../providers/RosterProvider';
import ChangeRightsMenu from './ChangeRightsMenu';
import RosterCell from './RosterCell';

interface Props extends Omit<RosterCellProps, 'name'> {
  attendeeId: string;
  extraIcon?: boolean;
  mayChangeRights?: boolean;
}

export const RosterAttendee: React.FC<Props> = ({
  attendeeId,
  mayChangeRights,
  ...rest
}) => {
  const { localAttendee } = useLocalAttendee();
  const { videoEnabled, sharingContent, muted } = useAttendeeStatus(attendeeId);
  const { raiseHandAttendeeIds } = useHandRaise();
  const { roster } = useRosterState();
  const { isOneOnOneMeeting } = useJoinMeeting();
  const attendee = roster[attendeeId];
  const attendeeName = attendee?.fullname || '';
  const description = attendee?.description || undefined;
  const hasRaisedHand = raiseHandAttendeeIds.includes(attendeeId);
  const requestsRights = attendee?.requestedRights?.size > 0;

  const getDescriptions = (
    description: string | undefined
  ): string[] | undefined => {
    if (description) {
      const separator = '\n';
      return description.split(separator);
    }
  };

  const showMenu = mayChangeRights && localAttendee.attendeeid !== attendeeId;

  return (
    <>
      <RosterCell
        css={'color: #ffffff'}
        name={attendeeName}
        descriptions={getDescriptions(description)}
        muted={muted}
        menuBadge={requestsRights}
        menu={(showMenu && !isOneOnOneMeeting) ? <ChangeRightsMenu attendee={attendee} /> : null}
        videoIcon={<Camera disabled={!videoEnabled} />}
        contentIcon={sharingContent && <ScreenShare />}
        extraIcon={hasRaisedHand ? <HandRaise className={'active'} /> : <></>}
        {...rest}
        microphone={<Microphone muted={muted} />}
      />
      {/* <PermissionsDebugCell attendee={attendee} /> */}
    </>
  );
};

export default RosterAttendee;
