import {
  ActionType,
  Severity,
  useAudioVideo,
  useNotificationDispatch,
} from 'amazon-chime-sdk-component-library-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../constants/routes';
import { useMeetingStatusProvider } from '../providers/MeetingMessagesProvider/MeetingStatusProvider';

const useMeetingEndRedirect = () => {
  const history = useHistory();
  const dispatch = useNotificationDispatch();
  const { meetingStatus } = useMeetingStatusProvider();
  const audioVideo = useAudioVideo();

  useEffect(() => {
    if (meetingStatus === 'ended') {
      dispatch({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: 'The meeting was ended by another attendee',
          autoClose: true,
          replaceAll: true,
        },
      });
      audioVideo?.stopVideoInput();
      audioVideo?.stopAudioInput()
      audioVideo?.stop();
      history.replace(routes.ENDED);
    }
  }, [meetingStatus, history, audioVideo, dispatch]);
};

export default useMeetingEndRedirect;
