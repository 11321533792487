import { FormField, RadioGroup } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import { ThemeName, useTheme } from '../../providers/ThemeProvider';

interface Props {}

export const ThemeSelection: React.FC<Props> = () => {

  const { setTheme, activeThemeName } = useTheme();

  const options: { value: ThemeName, label: string }[] = [
    { value: ThemeName.DARK, label: 'Dark theme' },
    { value: ThemeName.LIGHT, label: 'Light theme' },
  ];

  return (
    <FormField
      options={options}
      field={RadioGroup}
      onChange={(event: any) => setTheme(event?.target?.value)}
      value={activeThemeName}
      label="Theme selection"
    />
  );
};

export default ThemeSelection;
