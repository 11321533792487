enum BackendEvents {
  Webcam = 'webcam',
  Microphone = 'microphone',
  Screensharing = 'screensharing',
  Live = 'live',
  RaiseHand = 'raisehand',
  Promote = 'promote',
  UpdateRights = 'updateRights',
  RequestRights = 'requestRights',
}

export default BackendEvents