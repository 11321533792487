import { Label } from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import React from 'react';
import {
  useVideoBackground,
  VideoBackground,
} from '../../../providers/VideoBackgroundProvider';

import {
  StyledVideoBackgroundImage,
  StyledVideoBackgroundOptions,
} from '../Styled';

import useTranslate from '../../../hooks/useTranslate';
import { colors } from '../../../theme/light';

interface Props extends BaseSdkProps {
  label?: string;
}

export const VideoBackgroundSelection: React.FC<Props> = ({
  label = 'Background',
  ...rest
}) => {
  const {
    chooseActiveVideoBackground,
    activeVideoBackground,
    backgroundOptions,
  } = useVideoBackground();
  const translate = useTranslate();

  const clickableOptions = backgroundOptions.map(
    (videoBackground: VideoBackground) => {
      const borderStyle = {
        border: `3px solid ${colors?.appcolors.warn}`,
      };
      return (
        <StyledVideoBackgroundImage
          src={videoBackground.src}
          alt=""
          style={
            videoBackground.value === activeVideoBackground?.value
              ? borderStyle
              : {}
          }
          onClick={() => {
            chooseActiveVideoBackground(videoBackground);
          }}
        />
      );
    }
  );

  return (
    <div>
      <Label
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '.5rem',
        }}
      >
        {translate('VideoBackgrounds.Options.Label')}
      </Label>
      <StyledVideoBackgroundOptions>
        {clickableOptions}
      </StyledVideoBackgroundOptions>
    </div>
  );
};

export default VideoBackgroundSelection;
