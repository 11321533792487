import React from 'react'
import {
  ControlBar
} from 'amazon-chime-sdk-component-library-react';
import useTranslate from '../../../hooks/useTranslate';
import EndMeetingControl from '../../EndMeetingControl';
import NavigationControls from '../../NavigationControls';
import AudioInputControl from '../AudioInputControl';
import ContentShareControl from '../ContentShareControl';
import FullScreenControl from '../FullScreenControl';
import { StyledControls } from '../Styled';
import VideoInputControl from '../VideoInputControl';

const OneOnOneControls = () => {
  const translate = useTranslate();


  return (
    <StyledControls className="controls">
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels={false}
      >
        <AudioInputControl />
        <VideoInputControl label={translate('MeetingControls.VideoLabel')} />
        <ContentShareControl
          label={translate('MeetingControls.ContentLabel')}
          pauseLabel={translate('MeetingControls.ContentPauseLabel')}
          unpauseLabel={translate('MeetingControls.ContentUnpauseLabel')}
        />
        <NavigationControls />
        <FullScreenControl />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default OneOnOneControls;
