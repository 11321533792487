import { Dots, PopOver } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import styled from 'styled-components';
import IconButton, { IconButtonProps } from '../../Button/IconButton';

export interface PopOverMenuProps {
  menu: React.ReactNode;
  a11yMenuLabel?: string;
  buttonProps?: Partial<IconButtonProps>;
  menuBadge?: boolean;
}

export const PopOverMenu = ({
  menu,
  a11yMenuLabel = '',
  buttonProps,
  menuBadge,
}: PopOverMenuProps) => (
  <PopOver
    closeOnClick={false}
    className="ch-menu"
    a11yLabel={a11yMenuLabel}
    renderButtonWrapper={(isActive, props) => (
      <>
        <IconButton
          icon={<Dots />}
          {...buttonProps}
          {...props}
          className={buttonProps?.className}
          label={a11yMenuLabel}
        />
        {menuBadge ? <StyledBadge /> : null}
      </>
    )}
  >
    {menu}
  </PopOver>
);

const StyledBadge = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: '#FF0000';
  transform: translate(16px, -25px);
`;
