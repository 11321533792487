import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import { SpaceProps } from 'styled-system';
import { space } from 'styled-system';

export interface BaseProps extends SpaceProps, BaseSdkProps {
  tag?: any;
}

export const baseStyles = ({ css }: BaseProps) =>
  css ? `@media { ${css} };` : '';
export const baseSpacing = (props: BaseProps) => space(props);
