export const de = {
  'MeetingJoin.Info': 'Die Meeting-Verbindung wird vorbereitet',
  'MeetingJoin.Button': 'Weiter',
  'MeetingJoin.ErrorHeader': 'Es ist ein Fehler aufgetreten',
  'MeetingJoin.ErrorTitle': 'Keine Teilnahme am Meeting möglich',
  'MeetingJoin.ErrorDescription':
    'Es gab ein Problem beim Betreten dieses Meetings. Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
  'DeviceSetup.Header': 'Geräteeinstellungen',
  'MicrophoneDevices.AudioHeader': 'Audio',
  'MicrophoneDevices.AudioLabel': 'Mikrofon-Quelle',
  'MicrophoneDevices.AudioActivityLabel': 'Mikrofon-Aktivität',
  'SpeakerDevices.SpeakerLabel': 'Lautsprecher-Quelle',
  'CameraDevices.Header': 'Video',
  'CameraDevices.SelectionLabel': 'Kamera-Quelle',
  'CameraDevices.QualityLabel': 'Videoqualität',
  'CameraDevices.PreviewLabel': 'Videovorschau',
  'MeetingJoinDetails.ButtonLoading': 'Wird geladen ...',
  'MeetingJoinDetails.ButtonJoin': 'Meeting betreten',
  'MeetingJoinDetails.ErrorTitle': 'Keine Teilnahme am Meeting möglich',
  'MeetingJoinDetails.ErrorDescription':
    'Es gab ein Problem beim Betreten dieses Meetings. Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
  'MeetingJoinDetails.ErrorHeader': 'Es ist ein Fehler aufgetreten',
  'MeetingDetails.Info': 'Kein Teilnehmer hat seine Kamera aktiviert',
  'MeetingRoster.Title': 'Teilnehmer',
  'MeetingControls.MuteLabel': 'Mikrofon stummgeschaltet',
  'MeetingControls.UnmuteLabel': 'Stummschaltung aufheben',
  'MeetingControls.VideoLabel': 'Video',
  'MeetingControls.ContentLabel': 'Content', // Nicht gefunden
  'MeetingControls.ContentPauseLabel': 'Pause', // Nicht gefunden
  'MeetingControls.ContentUnpauseLabel': 'Unpause', // Nicht gefunden
  'MeetingControls.AudioLabel': 'Lautsprecher',
  'MeetingControls.EndLabel': 'Verlassen',
  'MeetingControls.MobileToggleLabel': 'Menü',
  'MeetingControls.LayoutToggleLabel': 'Layout',

  'EndMeetingControl.ModalTitle': 'Meeting verlassen',
  'EndMeetingControl.ModalTitleModerator': 'Meeting verlassen oder schliessen', // neuer Text für "Moderator"
  'EndMeetingControl.ModalText': 'Möchten Sie das Meeting verlassen?',
  'EndMeetingControl.ModalTextModerator':
    'Möchten Sie das Meeting verlassen oder schließen? Das Meeting kann nicht mehr verwendet werden, wenn es geschlossen ist.', // neuer Text für "Moderator"
  'EndMeetingControl.ModalButtonLeave': 'Meeting verlassen',
  'EndMeetingControl.ModalButtonEnd': 'Meeting schliessen für alle Teilnehmer',
  'EndMeetingControl.ModalButtonCancel': 'Abbrechen',

  'Navigation.Header': 'Navigation',
  'Navigation.ItemAttendee': 'Teilnehmer',
  'Navigation.ItemThemeDark': 'Dark mode',
  'Navigation.ItemThemeLight': 'Light mode',
  'Navigation.ItemChat': 'Chat',

  'DevicePermissionPrompt.Header': 'Device Label Permissions check', // Nicht gefunden
  'DevicePermissionPrompt.BodyTitle': 'Unable to get device labels', // Nicht gefunden
  'DevicePermissionPrompt.BodyText1':
    'In order to select media devices, we need to do a quick permissions check of your mic and camera.', // Nicht gefunden
  'DevicePermissionPrompt.BodyText2': 'When the pop-up appears, choose', // Nicht gefunden
  'DevicePermissionPrompt.BodyText3': 'Allow', // Nicht gefunden

  'KickedAttendee.Header': 'Sie können leider nicht an dem Meeting teilnehmen.',
  'KickedAttendee.Info': 'Die maximale Teilnehmerzahl wurde erreicht.',
  // 'MeetingControls.UnmuteLabel': 'Unmute',

  'PromotionModal.Title': 'Erweiterung Ihrer Berechtigungen',
  'PromotionModal.ModalText':
    'Im Moment haben Sie nicht die Rechte, Ihre Geräte zu aktivieren. Sie können aktualisierte Rechte für die Teilnahme am Meeting beantragen.',
  'PromotionModal.AskForRights': 'Berechtigung anfragen',
  'PromotionModal.ButtonCancel': 'Abbrechen',
  'Logo.altText': 'Logo',

  'ChangeRightsMenu.AllRights': 'Alle Geräte',
  'ChangeRightsMenu.VideoRight': 'Kamera',
  'ChangeRightsMenu.AudioRight': 'Mikrofon',
  'ChangeRightsMenu.ShareRight': 'Screenshare',
  'ChangeRightsMenu.RemoveRights': 'Alle Rechte entfernen',
  'ChangeRightsMenu.Title': 'Berechtigungen verwalten',

  'RequestRightsMenu.Title': 'Berechtigung anfragen',
  'RequestRightsMenu.RequestAllRights': 'Alle',

  'MeetingChat.Title': 'Chat',
  'MeetingSettings.AudioOutputControl': 'Stumm',

  'MeetingEnd.Header': 'Meeting beendet',
  'MeetingEnd.Info': 'Der Moderator hat das Meeting beendet',

  'MeetingLeft.Header': 'Meeting verlassen',
  'MeetingLeft.Info': 'Sie haben das Meeting verlassen.',
  'JoinMeetings.NotStarted':
    'Das Meeting wurde noch nicht gestartet. Bitte haben Sie einen Moment Geduld.',

  'BackgroundBlurCheckbox.Label': 'Hintergrundunschärfe aktivieren',
  'VideoInput.MaxVideosTitle': 'Maximale Anzahl Videos erreicht',
  'VideoInput.MaxVideosDescription':
    'Die maximale Anzahl von aktiven Videos ist derzeit erreicht. Sie können ihr Video derzeit nicht aktivieren.',

  'VideoBackgrounds.Options.BlurLabel': 'Hintergrundunschärfe',
  'VideoBackgrounds.Options.ImageLabel': 'Bild',
  'VideoBackgrounds.Options.NoneLabel': 'Kein Hintergrund',
  'VideoBackgrounds.Options.Label': 'Hintergrund',
};
