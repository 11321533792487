import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import BackendEvents from '../../enums/BackendEvents';
import {
  RequestRightsMessageType,
  UpdateRightsMessageType,
} from '../../types/MeetingMessage';
import {
  RosterAttendeeType,
  UnknownRosterAttendeeType,
} from '../../types/RosterAttendeeType';
import { UserRights } from '../../types/UserRights';
import { setPermissions } from '../../utils/api';
import { useLocalAttendee } from '../LocalAttendeeProvider';
import { useRosterState } from '../RosterProvider';
interface RightsManagementValue {
  requestAttendeeRights: (requested: UserRights[]) => void;
  updateAttendeeRights: (updateRights: UpdateRightsMessageType) => void;
}

interface RightsManagementProps {
  children: ReactNode;
}

export const useRightsManagement = () => {
  const state = useContext(RightsManagementContext);

  if (!state) {
    throw new Error(
      'useRightsManagement must be used within RightsManagementProvider'
    );
  }

  return state;
};

const RightsManagementContext = createContext<RightsManagementValue | null>(
  null
);

const RightsManagementProvider = ({ children }: RightsManagementProps) => {
  const audioVideo = useAudioVideo();
  const { localAttendee } = useLocalAttendee();
  const { updateAttendee } = useRosterState();

  const handleRightsRequest = useCallback(
    ({ attendeeId, requested }: RequestRightsMessageType) => {
      updateAttendee(attendeeId, (currentAttendee) => {
        if (!currentAttendee) {
          const newUnknownAttendee: UnknownRosterAttendeeType = {
            attendeeid: attendeeId,
            grantedRights: new Set(),
            revokedRights: new Set(),
            requestedRights: new Set(requested),
          };
          return newUnknownAttendee;
        } else {
          const currentRequestedRights = currentAttendee.requestedRights;
          const updatedRequestedRights = new Set([
            ...currentRequestedRights,
            ...requested,
          ]);
          const updatedAttendee: typeof currentAttendee = {
            ...currentAttendee,
            requestedRights: updatedRequestedRights,
          };

          return updatedAttendee;
        }
      });
    },
    [updateAttendee]
  );

  const handleRightsUpdate = useCallback(
    (
      { revoked, granted, attendeeId }: UpdateRightsMessageType,
      updateDataBase?: boolean
    ) => {
      updateAttendee(attendeeId, (currentAttendee) => {
        if (!currentAttendee) {
          const newUnknownAttendee: UnknownRosterAttendeeType = {
            attendeeid: attendeeId,
            revokedRights: new Set(revoked),
            grantedRights: new Set(granted),
            requestedRights: new Set(),
          };
          return newUnknownAttendee;
        } else {
          if ('id' in currentAttendee) {
            const currentRights = currentAttendee.rights;

            let updatedRights = [...currentRights, ...granted];
            updatedRights = updatedRights.filter(
              (right) => !revoked.includes(right)
            );

            const currentRequestedRights = [...currentAttendee.requestedRights];
            const updatedRequestedRights = currentRequestedRights.filter(
              (requestedRight) =>
                !revoked.includes(requestedRight) &&
                !granted.includes(requestedRight)
            );

            const updatedAttendee: RosterAttendeeType = {
              ...currentAttendee,
              rights: new Set(updatedRights),
              requestedRights: new Set(updatedRequestedRights),
            };

            if (updateDataBase) {
              setPermissions(attendeeId, updatedRights);
            }

            return updatedAttendee;
          } else {
            const updatedRevokedRights = new Set([
              ...currentAttendee.revokedRights,
              ...revoked,
            ]);

            const updatedGrantedRights = new Set([
              ...currentAttendee.grantedRights,
              ...granted,
            ]);

            const updatedAttendee: UnknownRosterAttendeeType = {
              ...currentAttendee,
              revokedRights: updatedRevokedRights,
              grantedRights: updatedGrantedRights,
            };

            return updatedAttendee;
          }
        }
      });
    },
    [updateAttendee]
  );

  const requestAttendeeRights = useCallback(
    (requested: UserRights[]) => {
      const requestRights: RequestRightsMessageType = {
        requested,
        attendeeId: localAttendee.attendeeid,
      };
      audioVideo?.realtimeSendDataMessage(
        BackendEvents.RequestRights,
        requestRights,
        300000
      );
      // Update own roster
      handleRightsRequest(requestRights);
    },
    [audioVideo, handleRightsRequest, localAttendee]
  );

  const updateAttendeeRights = useCallback(
    (updateRights: UpdateRightsMessageType) => {
      audioVideo?.realtimeSendDataMessage(
        BackendEvents.UpdateRights,
        updateRights,
        300000
      );
      // Update own roster + database
      handleRightsUpdate(updateRights, true);
    },
    [audioVideo, handleRightsUpdate]
  );

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const handleRightsRequestDataMessage = (data: DataMessage) => {
      const requestedRightsEvent: RequestRightsMessageType = data.json();
      handleRightsRequest(requestedRightsEvent);
    };

    const handleRightsUpdateDataMessage = (data: DataMessage) => {
      const changedRightsEvent: UpdateRightsMessageType = data.json();
      handleRightsUpdate(changedRightsEvent);
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      BackendEvents.RequestRights,
      handleRightsRequestDataMessage
    );

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      BackendEvents.UpdateRights,
      handleRightsUpdateDataMessage
    );

    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
        BackendEvents.RequestRights
      );

      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
        BackendEvents.UpdateRights
      );
    };
  }, [audioVideo, handleRightsUpdate, handleRightsRequest]);

  const RightsManagementValue: RightsManagementValue = {
    requestAttendeeRights,
    updateAttendeeRights,
  };
  return (
    <RightsManagementContext.Provider value={RightsManagementValue}>
      {children}
    </RightsManagementContext.Provider>
  );
};
export default RightsManagementProvider;
