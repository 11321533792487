import React from 'react';
import { BaseProps } from '../../components/Base';
import Logo from '../../components/Logo';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import { useJoinMeeting } from '../../providers/JoinMeetingProvider';
import EndMeetingControl from '../EndMeetingControl';
import MeetingControls from '../MeetingControls';
import NavigationControls from '../NavigationControls';
import {
  StyledMeetingControls,
  StyledMeetingHeader,
  StyledMeetingInfo,
  StyledMeetingTitle
} from './Styled';

interface Props extends BaseProps {
  noControls?: boolean | null;
}

const MeetingHeader: React.FC<Props> = ({ noControls }) => {
  const { joinInfo } = useJoinMeeting();
  useMeetingEndRedirect();

  return (
    <>
      {joinInfo && (
        <StyledMeetingHeader>
          <StyledMeetingInfo>
            <Logo width={'40px'} height={'40px'} />
            <StyledMeetingTitle>
              <p>{joinInfo?.meeting.caption}</p>
              <p>{joinInfo?.meeting.description}</p>
            </StyledMeetingTitle>
          </StyledMeetingInfo>
          {!noControls && (
            <StyledMeetingControls>
              <MeetingControls />
              <NavigationControls />
              <EndMeetingControl />
            </StyledMeetingControls>
          )}
        </StyledMeetingHeader>
      )}
    </>
  );
};

export default MeetingHeader;
