import {
  useAudioVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { DefaultModality } from 'amazon-chime-sdk-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  RosterAttendeeType,
  UnknownRosterAttendeeType,
} from '../types/RosterAttendeeType';
import RosterType, { AbsentRosterType } from '../types/RosterType';
import { getAttendee } from '../utils/api';
import { mergeIncomingWithKnownAttendee } from '../utils/rosterUtils';
import { useStartMeeting } from './StartMeetingProvider';

interface RosterContextValue {
  roster: RosterType;
  updateAttendee: (
    attendeeId: string,
    callback: (
      attendee: RosterAttendeeType | UnknownRosterAttendeeType | undefined
    ) => RosterAttendeeType | UnknownRosterAttendeeType
  ) => void;
}

type RosterByCurrentPresenceType = {
  present: RosterType;
  absent: AbsentRosterType;
};

const RosterContext = React.createContext<RosterContextValue | null>(null);

const RosterProvider: React.FC = ({ children }) => {
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const meetingStarted = useStartMeeting();
  const rosterRef = useRef<RosterType>({});
  const [roster, setRoster] = useState<RosterByCurrentPresenceType>({
    present: {},
    absent: {},
  });

  useEffect(() => {
    console.log({ roster });
  }, [roster]);

  const updateAttendee = useCallback(
    (
      attendeeId: string,
      callback: (
        attendee: RosterAttendeeType | UnknownRosterAttendeeType | undefined
      ) => RosterAttendeeType | UnknownRosterAttendeeType
    ) => {
      setRoster((currentRoster) => {
        const presentAttendee = currentRoster.present[attendeeId];
        if (presentAttendee) {
          const updatedAttendee = callback(
            presentAttendee
          ) as RosterAttendeeType;
          return {
            ...currentRoster,
            present: {
              ...currentRoster.present,
              [attendeeId]: updatedAttendee,
            },
          };
        } else {
          const absentAttendee = currentRoster.absent[attendeeId];
          const updatedAttendee = callback(absentAttendee);
          return {
            ...currentRoster,
            absent: {
              ...currentRoster.absent,
              [attendeeId]: updatedAttendee,
            },
          };
        }
      });
    },
    []
  );

  useEffect(() => {
    if (!audioVideo || !meetingStarted) {
      return;
    }

    const rosterUpdateCallback = async (
      chimeAttendeeId: string,
      present: boolean
    ): Promise<void> => {
      if (!present) {
        delete rosterRef.current[chimeAttendeeId];

        // Need to keep absent users in roster to store their current rights
        // Otherwise we would use the cached rights from the server after they refresh
        setRoster((currentRoster: RosterByCurrentPresenceType) => {
          const { [chimeAttendeeId]: leavingAttendee, ...newPresentRoster } =
            currentRoster.present;
          return {
            present: newPresentRoster,
            absent: {
              ...currentRoster.absent,
              [chimeAttendeeId]: leavingAttendee,
            },
          };
        });

        return;
      }

      const attendeeId = new DefaultModality(chimeAttendeeId).base();
      if (attendeeId !== chimeAttendeeId) {
        return;
      }

      const inRoster = rosterRef.current[chimeAttendeeId];
      if (inRoster) {
        return;
      }

      let attendee: RosterAttendeeType;
      const response = await getAttendee(attendeeId);

      const responseBody: RosterAttendeeType = response.data.data;

      if (!responseBody) {
        throw Error(`Missing User in Attendee List`);
      }
      attendee = responseBody;
      attendee.requestedRights = new Set();
      attendee.rights = new Set(attendee.rights);
      rosterRef.current[attendeeId] = attendee;

      setRoster((currentRoster: RosterByCurrentPresenceType) => {
        const { [chimeAttendeeId]: knownAttendee, ...newAbsentRoster } =
          currentRoster.absent;

        // If we already know the attendee from our roster,
        // then our knowledge about his rights is more recent than the servers knowlede
        const newAttendee = mergeIncomingWithKnownAttendee(
          attendee,
          knownAttendee
        );

        return {
          present: { ...currentRoster.present, [chimeAttendeeId]: newAttendee },
          absent: newAbsentRoster,
        };
      });
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      // setRoster({});
      // rosterRef.current = {};
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, meetingManager, meetingStarted]);

  const value = useMemo(
    () => ({
      roster: roster.present,
      updateAttendee,
    }),
    [roster, updateAttendee]
  );

  return (
    <RosterContext.Provider value={value}>{children}</RosterContext.Provider>
  );
};

function useRosterState(): RosterContextValue {
  const state = useContext(RosterContext);

  if (!state) {
    throw new Error('userRosterState must be used within RosterProvider');
  }

  return state;
}

export { RosterProvider, useRosterState };
