import styled from 'styled-components';
import { RosterCellProps } from '.';
import { ellipsis } from '../../../utils/style';
import { baseSpacing, baseStyles } from '../../Base';

export const StyledCell = styled.div<RosterCellProps>`
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;

  .active {
    background-color: ${props => props.theme.colors.primary.main};
    border-radius: 0.25rem;
  }

  .ch-mic {
    flex-shrink: 0;
    width: 1.5rem;
    line-height: 0;
  }

  svg {
    width: 1.5rem;
    flex-shrink: 0;
    color: ${props => props.theme.colors.info.lightest}
  }

  > * {
    margin-right: 0.5rem;
  }

  > :last-child {
    margin-right: 0;
  }

  ${baseSpacing}
  ${baseStyles}
`;
export const StyledName = styled.div`
  flex-grow: 1;
  min-width: 0;
  line-height: 1.5;

  .ch-name {
    ${ellipsis};
    font-size: 0.875rem;
    color: ${(props) => props.theme.roster.primaryText};
  }

  .ch-subtitle {
    ${ellipsis};
    font-size: 0.65rem;
    color: ${(props) => props.theme.roster.secondaryText};
  }
`;

export const StyledDescription = styled.div`
  .ch-description {
    ${ellipsis};
    font-size: 0.65rem;
    color: ${(props) => props.theme.roster.secondaryText};
  }
`;
