import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { DataMessage } from 'amazon-chime-sdk-js';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DataMessageType } from '../../types/MeetingMessage';

interface MeetingStatusContextValue {
  meetingStatus: string;
}

const MeetingStatusContext = createContext<MeetingStatusContextValue | null>({
  meetingStatus: '',
});

const MeetingStatusProvider: React.FC = ({ children }) => {
  const audioVideo = useAudioVideo();

  const [meetingStatus, setMeetingStatus] = useState<string>('');

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const messageCallback = async (data: DataMessage) => {
      if (data.topic !== 'meetingStatus') {
        return;
      }
      const meetingStatusEvent: DataMessageType = data.json();

      if (meetingStatusEvent.eventStatus === 'started') {
        setMeetingStatus('started');
      }
      if (meetingStatusEvent.eventStatus === 'ended') {
        setMeetingStatus('ended');
      }
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      'meetingStatus',
      messageCallback
    );

    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage('meetingStatus');
    };
  }, []);

  const value = useMemo(() => ({ meetingStatus }), [meetingStatus]);

  return (
    <MeetingStatusContext.Provider value={value}>
      {children}
    </MeetingStatusContext.Provider>
  );
};

function useMeetingStatusProvider() {
  const state = useContext(MeetingStatusContext);

  if (!state) {
    throw new Error(
      'useMeetingStatus must be used within MeetingStatusProvider'
    );
  }

  return state;
}

export { MeetingStatusProvider, useMeetingStatusProvider };
