import { UpAndDownCaret } from 'amazon-chime-sdk-component-library-react';
import React, { useState } from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';

interface Props {
  label?: string;
}

const FullScreenControl: React.FC<Props> = ({ label }) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullScreen = () => {
    if (!fullscreen) {
      window.parent.postMessage('fullscreen', '*');
      setFullscreen(!fullscreen);
    } else {
      window.parent.postMessage('not fullscreen', '*');
      setFullscreen(!fullscreen);
    }
  };
  return (
    <>
      <ControlBarButton
        title={label}
        icon={<UpAndDownCaret style={{ transform: 'rotate(45deg)' }} />}
        onClick={handleFullScreen}
      />
    </>
  );
};
export default FullScreenControl;
