import { Microphone } from 'amazon-chime-sdk-component-library-react';
import { BaseProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import React, { forwardRef, HTMLAttributes } from 'react';
import { StyledVideoTile } from './Styled';

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

export interface VideoTileProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
    BaseProps {
  nameplate?: string | null;
  objectFit?: ObjectFit;
  active?: boolean;
  showMuted?: boolean;
}

export const VideoTile = forwardRef(
  (props: VideoTileProps, ref: React.Ref<HTMLVideoElement>) => {
    const { tag, className, nameplate, showMuted, ...rest } = props;

    return (
      <StyledVideoTile
        as={tag}
        className={className || ''}
        data-testid="video-tile"
        {...rest}
      >
        <video ref={ref} className="ch-video" />
        {nameplate && (
          <>
            <header className="ch-nameplate">
              <p className="ch-text">{nameplate}</p>
              {showMuted && <Microphone className="mute-icon" muted />}
            </header>
          </>
        )}
      </StyledVideoTile>
    );
  }
);

export default VideoTile;
