import {
  Camera,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import { useRosterState } from '../../providers/RosterProvider';
import { StyledPlaceholder } from './Styled';

interface Props {
  attendeeId: string;
  extraIcon?: boolean;
}

export const PlaceholderItem: React.FC<Props> = ({ attendeeId, ...rest }) => {
  const { videoEnabled } = useAttendeeStatus(attendeeId);
  const { roster } = useRosterState();
  const attendeeName = roster[attendeeId]?.fullname || '';

  return (
    <>
      {videoEnabled ? (
        <></>
      ) : (
        <StyledPlaceholder>
          <div className={'icon-container'}>
            <Camera className={'icon'} disabled />
          </div>
          <header className="ch-nameplate">
            <p className="ch-text">{attendeeName}</p>
          </header>
        </StyledPlaceholder>
      )}
    </>
  );
};

export default PlaceholderItem;
