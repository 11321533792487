import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { BaseProps } from '../../Base';
import { MessageVariant } from './ChatBubbleContainer';
import { StyledChatBubble } from './Styled';

export interface ChatBubbleProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
    BaseProps {
  variant: MessageVariant;
  senderName: string;
  content: string | any;
  showName?: boolean;
  showTail?: boolean;
  redacted?: boolean;
  children?: ReactNode | ReactNode[];
}

export const ChatBubble: FC<ChatBubbleProps> = ({
  variant,
  senderName,
  content,
  showName = true,
  showTail,
  redacted,
  children,
  ...rest
}) => {
  return (
    <StyledChatBubble
      variant={variant}
      editable={false}
      {...rest}
      >
      {showName && <div className="ch-sender-name">{senderName}</div>}
      <div>{content}</div>
      {children}
    </StyledChatBubble>
  );
};

export default ChatBubble;
