export const en = {
  'MeetingJoin.Info': 'Preparing meeting connection',
  'MeetingJoin.Button': 'Continue',
  'MeetingJoin.ErrorHeader': 'An error has occurred',
  'MeetingJoin.ErrorTitle': 'Unable to join meeting',
  'MeetingJoin.ErrorDescription':
    'There was an issue finding that meeting. Check your connectivity and try again.',
  'DeviceSetup.Header': 'Device settings',
  'MicrophoneDevices.AudioHeader': 'Audio',
  'MicrophoneDevices.AudioLabel': 'Microphone source',
  'MicrophoneDevices.AudioActivityLabel': 'Microphone activity',
  'SpeakerDevices.SpeakerLabel': 'Speaker source',
  'CameraDevices.Header': 'Video',
  'CameraDevices.SelectionLabel': 'Camera source',
  'CameraDevices.QualityLabel': 'Video quality',
  'CameraDevices.PreviewLabel': 'Video preview',
  'MeetingJoinDetails.ButtonLoading': 'Loading ...',
  'MeetingJoinDetails.ButtonJoin': 'Join meeting',
  'MeetingJoinDetails.ErrorTitle': 'Unable to join meeting',
  'MeetingJoinDetails.ErrorDescription':
    'There was an issue in joining this meeting. Check your connectivity and try again.',
  'MeetingJoinDetails.ErrorHeader': 'Meeting error',
  'MeetingDetails.Info': 'Nobody is sharing video.',
  'MeetingRoster.Title': 'Attendees',
  'MeetingControls.MuteLabel': 'Mute',
  'MeetingControls.UnmuteLabel': 'Unmute',
  'MeetingControls.VideoLabel': 'Video',
  'MeetingControls.ContentLabel': 'Content',
  'MeetingControls.ContentPauseLabel': 'Pause',
  'MeetingControls.ContentUnpauseLabel': 'Unpause',
  'MeetingControls.AudioLabel': 'Speaker',
  'MeetingControls.EndLabel': 'Leave',
  'MeetingControls.MobileToggleLabel': 'Menu',
  'MeetingControls.LayoutToggleLabel': 'Layout',

  'EndMeetingControl.ModalTitle': 'Leave Meeting',
  'EndMeetingControl.ModalTitleModerator': 'Leave or Close Meeting',
  'EndMeetingControl.ModalText': 'Do you want to leave the meeting?',
  'EndMeetingControl.ModalTextModerator':
    'Do you want to leave or close the meeting? The meeting cannot be used once it is closed.',
  'EndMeetingControl.ModalButtonLeave': 'Leave meeting',
  'EndMeetingControl.ModalButtonEnd': 'Close meeting for all attendees',
  'EndMeetingControl.ModalButtonCancel': 'Cancel',

  'Navigation.Header': 'Navigation',
  'Navigation.ItemAttendee': 'Attendees',
  'Navigation.ItemThemeDark': 'Dark mode',
  'Navigation.ItemThemeLight': 'Light mode',
  'Navigation.ItemChat': 'Chat',

  'DevicePermissionPrompt.Header': 'Device Label Permissions check', // Nicht gefunden
  'DevicePermissionPrompt.BodyTitle': 'Unable to get device labels', // Nicht gefunden
  'DevicePermissionPrompt.BodyText1':
    'In order to select media devices, we need to do a quick permissions check of your mic and camera.', // Nicht gefunden
  'DevicePermissionPrompt.BodyText2': 'When the pop-up appears, choose', // Nicht gefunden
  'DevicePermissionPrompt.BodyText3': 'Allow', // Nicht gefunden

  'KickedAttendee.Header': 'Sorry, you cannot join the meeting.',
  'KickedAttendee.Info': 'The maximum number of participants was reached.',
  // 'MeetingControls.UnmuteLabel': 'Unmute',

  'PromotionModal.Title': 'Ask for promotion',
  'PromotionModal.ModalText':
    'Right now you dont have the rights to activate your devices. You can ask for updated rights to participate in meeting.',
  'PromotionModal.AskForRights': 'Ask',
  'PromotionModal.ButtonCancel': 'Cancel',
  'Logo.altText': 'Logo',

  'ChangeRightsMenu.AllRights': 'All Devices',
  'ChangeRightsMenu.VideoRight': 'Video',
  'ChangeRightsMenu.AudioRight': 'Microphone',
  'ChangeRightsMenu.ShareRight': 'Screenshare',
  'ChangeRightsMenu.RemoveRights': 'Remove rights',
  'ChangeRightsMenu.Title': 'Manage Permissions',

  'RequestRightsMenu.Title': 'Request permissions',
  'RequestRightsMenu.RequestAllRights': 'All',

  'MeetingChat.Title': 'Chat',
  'MeetingSettings.AudioOutputControl': 'Mute',

  'MeetingEnd.Header': 'Meeting ended',
  'MeetingEnd.Info': 'The moderator has ended the meeting.',

  'MeetingLeft.Header': 'Meeting left',
  'MeetingLeft.Info': 'You have successfully left the meeting.',
  'JoinMeetings.NotStarted':
    'The meeting has not started yet. Please be patient for a moment.',

  'BackgroundBlurCheckbox.Label': 'Blur my background',
  'VideoInput.MaxVideosTitle': 'Active video limit reached',
  'VideoInput.MaxVideosDescription':
    "The maximum of active Videos is reached right now. You can't activate your video.",

  'VideoBackgrounds.Options.BlurLabel': 'Blur',
  'VideoBackgrounds.Options.ImageLabel': 'Image',
  'VideoBackgrounds.Options.NoneLabel': 'None',
  'VideoBackgrounds.Options.Label': 'Background',
};
