import {
  useContentShareState,
  useFeaturedTileState,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import React, { FC, HTMLAttributes, memo } from 'react';
import { useRosterState } from '../../../providers/RosterProvider';
import RemoteVideo from '../RemoteVideo';
import { useGridData } from '../VideoGrid';

interface Props
  extends BaseSdkProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'css'> {}

export const FeaturedRemoteVideos: FC<Props> = (props) => {
  const gridData = useGridData();
  const { roster } = useRosterState();
  const { tileId: featuredTileId } = useFeaturedTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();

  return (
    <>
      {tiles.map((tileId) => {
        const featured = !contentTileId && featuredTileId === tileId;
        const styles = gridData && featured ? 'grid-area: ft;' : '';
        const classes = `${featured ? 'ch-featured-tile' : ''} ${
          props.className || ''
        }`;
        const attendee = roster[tileIdToAttendeeId[tileId]];
        const fullname  = attendee?.fullname || '';

        return (
          <RemoteVideo
            tileId={tileId}
            attendeeId={attendee.attendeeid}
            name={fullname}
            active={featured}
            {...props}
            className={classes}
            key={tileId}
            css={styles}
          />
        );
      })}
    </>
  );
};

export default memo(FeaturedRemoteVideos);
