import {
  Roster,
  RosterGroup,
  RosterHeader,
} from 'amazon-chime-sdk-component-library-react';
import React, { ChangeEvent, useState } from 'react';
import RosterAttendee from '../components/Roster/RosterAttendee';
import useTranslate from '../hooks/useTranslate';
import { useLocalAttendee } from '../providers/LocalAttendeeProvider';
import { useHandRaise } from '../providers/MeetingMessagesProvider/HandRaiseProvider';
import { useNavigation } from '../providers/NavigationProvider';
import { useRosterState } from '../providers/RosterProvider';
import { RosterAttendeeType } from '../types/RosterAttendeeType';
import { UserRights } from '../types/UserRights';
import { sortUserList } from '../utils/sortHelper';
interface MeetingRosterProps {
  hidden?: boolean;
}

const MeetingRoster: React.FC<MeetingRosterProps> = (props) => {
  const { localAttendee } = useLocalAttendee();
  let { roster } = useRosterState();
  const { closeRoster } = useNavigation();
  const translate = useTranslate();
  const [filter, setFilter] = useState('');
  const { hidden } = props;
  const { raiseHandAttendeeIds } = useHandRaise();

  let attendees = Object.values(roster);

  if (filter) {
    attendees = attendees.filter((attendee: RosterAttendeeType) =>
      attendee?.fullname.toLowerCase().includes(filter.trim().toLowerCase())
    );
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const sortedAttendees = sortUserList(attendees, raiseHandAttendeeIds);
  const mayChangeRights =
    localAttendee?.rights.has(UserRights.ALLOW_DISABLE_CAMERA) ||
    localAttendee?.rights.has(UserRights.ALLOW_MUTE);
  const attendeeItems = sortedAttendees.map((attendee: RosterAttendeeType) => {
    return (
      <RosterAttendee
        key={attendee.attendeeid}
        mayChangeRights={mayChangeRights}
        attendeeId={attendee.attendeeid}
      />
    );
  });

  return (
    <Roster className="roster" css={hidden ? 'display: none' : ''}>
      <RosterHeader
        searchValue={filter}
        onSearch={handleSearch}
        onClose={closeRoster}
        title={translate('MeetingRoster.Title')}
        badge={attendees.length}
      />
      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  );
};

export default MeetingRoster;
