import {
  Caret,
  PopOver,
  PopOverItem,
} from 'amazon-chime-sdk-component-library-react';
import { Placement } from 'amazon-chime-sdk-component-library-react/lib/components/ui/PopOver';
import { PopOverItemProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/PopOver/PopOverItem';
import React, { FC, ReactNode } from 'react';
import { BaseProps } from '../Base';
import IconButton from './IconButton';
import { StyledControlBarItem } from './Styled';

export interface ControlBarButtonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'css'>,
    BaseProps {
  icon: JSX.Element;
  onClick?: () => void;
  isSelected?: boolean;
  popOver?: PopOverItemProps[] | null;
  popOverPlacement?: Placement;
  popOverLabel?: string;
  disabled?: boolean;
  children?: ReactNode | ReactNode[];
}

export const ControlBarButton: FC<ControlBarButtonProps> = ({
  icon,
  onClick,
  isSelected = false,
  popOver = null,
  popOverPlacement,
  popOverLabel,
  children,
  disabled,
  ...rest
}) => {
  const renderPopOver = () => (
    <PopOver
      renderButtonWrapper={(isActive, props) => (
        <IconButton
          {...props}
          icon={<Caret direction={'up'} data-testid="control-bar-item-caret" />}
          label={popOverLabel || ''}
          selected={isActive}
          disabled={disabled}
          className={`ch-control-bar-item-caret ${isActive ? 'isOpen' : ''}`}
        />
      )}
      a11yLabel={''}
      className="ch-control-bar-popover"
      placement={popOverPlacement}
    >
      {popOver?.map((option: PopOverItemProps, index: number) => (
        <PopOverItem {...option} key={index} />
      ))}
      {children}
    </PopOver>
  );

  return (
    <StyledControlBarItem {...rest}>
      <IconButton
        onClick={onClick}
        icon={icon}
        disabled={disabled}
        className="ch-control-bar-item-iconButton"
      />
      {(popOver || children) && renderPopOver()}
    </StyledControlBarItem>
  );
};

export default ControlBarButton;
