import {
  RosterAttendeeType,
  UnknownRosterAttendeeType,
} from '../types/RosterAttendeeType';

export const mergeIncomingWithKnownAttendee = (
  incomingAttendee: RosterAttendeeType,
  knownAttendee?: RosterAttendeeType | UnknownRosterAttendeeType
) => {
  if (!knownAttendee) {
    return incomingAttendee;
  }
  if ('id' in knownAttendee) {
    return knownAttendee;
  } else {
    const incomingAndGrantedRights = [
      ...incomingAttendee.rights,
      ...knownAttendee.grantedRights,
    ];
    const updatedRights = incomingAndGrantedRights.filter(
      (right) => !knownAttendee.revokedRights.has(right)
    );
    const mergedAttendee: RosterAttendeeType = {
      ...incomingAttendee,
      rights: new Set(updatedRights),
      requestedRights: knownAttendee.requestedRights,
    };
    return mergedAttendee;
  }
};
