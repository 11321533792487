import {
  useApplyVideoObjectFit,
  useAudioVideo,
  useLocalVideo,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base';
import { VideoTileState } from 'amazon-chime-sdk-js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAudioVideoAttendees } from '../../../providers/MeetingMessagesProvider/AudioVideoAttendeeProvider';
import VideoTile from '../VideoTile';

interface Props extends BaseSdkProps {
  id?: string;
  nameplate?: string;
}

const StyledLocalVideo = styled<any>(VideoTile)`
  ${(props) => (!props.activated ? 'display: none' : '')};
`;

export const LocalVideo: React.FC<Props> = ({ nameplate, ...rest }) => {
  const { muted } = useToggleLocalMute();
  const { isVideoEnabled } = useLocalVideo();

  const { tileId } = useAudioVideoAttendees();
  const audioVideo = useAudioVideo();
  const videoEl = useRef<HTMLVideoElement>(null);
  useApplyVideoObjectFit(videoEl);
  const [activated, setActivated] = useState(() =>
    audioVideo?.hasStartedLocalVideoTile()
  );

  useEffect(() => {
    if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId, isVideoEnabled]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const observer = {
      videoTileDidUpdate: (tileState: VideoTileState) => {
        if (
          !tileState.boundAttendeeId ||
          !tileState.localTile ||
          !tileState.tileId ||
          !videoEl.current
        ) {
          return;
        }

        if (tileState.active !== activated) {
          setActivated(tileState.active);
        }
      },
    };

    audioVideo.addObserver(observer);

    return () => audioVideo.removeObserver(observer);
  }, [audioVideo, activated]);

  return (
    <StyledLocalVideo
      activated={activated}
      nameplate={nameplate}
      ref={videoEl}
      showMuted={muted}
      {...rest}
    ></StyledLocalVideo>
  );
};

export default LocalVideo;
