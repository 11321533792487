import {
  Camera,
  Modal,
  ModalBody, useLocalVideo
} from 'amazon-chime-sdk-component-library-react';
import React, { useEffect, useState } from 'react';
import ControlBarButton from '../../components/Button/ControlBarItem';
import Card from '../../components/Card';
import useTranslate from '../../hooks/useTranslate';
import { useRights } from '../../providers/RightsProvider';
import { UserRights } from '../../types/UserRights';
import RequestPermissionsControl from './RequestPermissionsControl/RequestPermissionsControl';

interface Props {
  label?: string;
}

const VideoInputControl: React.FC<Props> = ({ label = 'Video' }) => {
  const { hasRight } = useRights();
  const { isVideoEnabled, toggleVideo, hasReachedVideoLimit } = useLocalVideo();
  const [ showModal, setShowModal] = useState<boolean>(false)
  const cameraAllowed = hasRight(UserRights.ALLOW_ACTIVE_CAMERA);
  const translate = useTranslate();

  const handleClick = () => {
    if (hasReachedVideoLimit) {
      setShowModal(true)
    } else {
      toggleVideo()
    }
  }

  useEffect(() => {
    if (!cameraAllowed) {
      isVideoEnabled && toggleVideo();
    }
  }, [cameraAllowed, isVideoEnabled, toggleVideo]);

  return (
    <>
      {cameraAllowed ? (
        <>
          <ControlBarButton
            title={isVideoEnabled ? 'Video' : 'Video off'}
            className={!isVideoEnabled ? 'inactive' : undefined}
            icon={<Camera disabled={!isVideoEnabled} />}
            disabled={!cameraAllowed}
            onClick={handleClick}
          />
           {showModal && (
            <Modal size="md" onClose={(): void => setShowModal(false)}>
              <ModalBody>
                <Card
                  title={translate('VideoInput.MaxVideosTitle')}
                  description={translate('VideoInput.MaxVideosDescription')}
                />
              </ModalBody>
            </Modal>
    	     )}
        </>

      ) : (
        <RequestPermissionsControl icon={<Camera disabled />} />
      )}
    </>
  );
};

export default VideoInputControl;
