import styled from 'styled-components';
interface StyledProps {
  isOneOnOneMeeting: boolean | null;
}

export const StyledMeetingChat = styled.div<StyledProps>`
  --chat-height: ${(props) =>
    props.isOneOnOneMeeting ? 'calc(100vh - 112px)' : 'calc(100vh - 57px)'};
  display: flex;
  flex-direction: column;
  width: 18.5rem;
  height: var(--chat-height, 100vh);
  background-color: ${(props) => props.theme.chat.bgd};
  box-shadow: 1rem 1rem 3.75rem 0 rgba(0, 0, 0, 0.1);
  border-left: 0.0625rem solid ${(props) => props.theme.colors.greys.grey30};
  border-right: 0.0625rem solid ${(props) => props.theme.colors.greys.grey30};
`;

export const StyledChatMessageContainer = styled.div`
  overflow-y: auto;
`;
