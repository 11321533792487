import React from 'react';
import { useRosterState } from '../../providers/RosterProvider';
import { RosterAttendeeType } from '../../types/RosterAttendeeType';
import PlaceholderItem from './PlaceholderItem';
import { StyledContainer } from './Styled';

interface PlaceholderProps extends React.HTMLAttributes<HTMLDivElement> {}

const Placeholders: React.FC<PlaceholderProps> = () => {
  const { roster } = useRosterState();
  let attendees = Object.values(roster);

  const placeholderItems = attendees.map((attendee: RosterAttendeeType) => {
    return (
      <PlaceholderItem
        key={attendee.attendeeid}
        attendeeId={attendee.attendeeid}
      />
    );
  });

  return <StyledContainer>{placeholderItems}</StyledContainer>;
};

export default Placeholders;
