import { Microphone } from 'amazon-chime-sdk-component-library-react';
import { FocusableProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import React from 'react';
import { BaseProps } from '../../Base';
import { IconButtonProps } from '../../Button/IconButton';
import { PopOverMenu } from './PopOverMenu';
import RosterName from './RosterName';
import { StyledCell } from './Styled';

type MicPosition = 'leading' | 'grouped';

export interface RosterCellProps extends BaseProps, FocusableProps {
  name: string;
  subtitle?: string;
  runningLate?: string;
  micPosition?: MicPosition;
  muted: boolean;
  videoIcon?: React.ReactNode;
  contentIcon?: React.ReactNode;
  poorConnection?: boolean;
  microphone?: React.ReactNode;
  a11yMenuLabel?: string;
  extraIcon?: React.ReactNode;
  buttonProps?: Partial<IconButtonProps>;
  descriptions?: string[];
  menu?: React.ReactNode;
  menuBadge?: boolean;
}

export const RosterCell: React.FC<RosterCellProps> = (props) => {
  const {
    tag,
    name,
    subtitle,
    className,
    muted,
    videoIcon,
    contentIcon,
    poorConnection = false,
    microphone,
    extraIcon,
    descriptions,
    menu,
    menuBadge,
    buttonProps,
  } = props;

  const mic = microphone || (
    <Microphone muted={muted} poorConnection={poorConnection} />
  );

  return (
    <StyledCell className={className || ''} as={tag} {...props}>
      <RosterName name={name} subtitle={subtitle} descriptions={descriptions} />

      {menu && (
        <PopOverMenu
          menu={menu}
          a11yMenuLabel={''}
          buttonProps={buttonProps}
          menuBadge={menuBadge}
        />
      )}
      <>
        <div className="ch-mic">{mic}</div>
        {videoIcon}
        {contentIcon}
        {extraIcon}
      </>
    </StyledCell>
  );
};

export default RosterCell;
