export enum UserRights {
  ALLOW_ACTIVE_AUDIO = "ALLOW_ACTIVE_AUDIO",
  ALLOW_ACTIVE_CAMERA = "ALLOW_ACTIVE_CAMERA",
  ALLOW_ACTIVE_SHARE = "ALLOW_ACTIVE_SHARE",
  ALLOW_CLOSE_MEETING = "ALLOW_CLOSE_MEETING",
  ALLOW_DECIDE_REQUEST_FOR_AUDIO = "ALLOW_DECIDE_REQUEST_FOR_AUDIO",
  ALLOW_DECIDE_REQUEST_FOR_SHARE = "ALLOW_DECIDE_REQUEST_FOR_SHARE",
  ALLOW_DECIDE_REQUEST_FOR_VIDEO = "ALLOW_DECIDE_REQUEST_FOR_VIDEO",
  ALLOW_DISABLE_CAMERA = "ALLOW_DISABLE_CAMERA",
  ALLOW_ENABLE_SPOTLIGHT = "ALLOW_ENABLE_SPOTLIGHT",
  ALLOW_MUTE = "ALLOW_MUTE",
  ALLOW_START_MEETING = "ALLOW_START_MEETING"
}
