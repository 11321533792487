import { PrimaryButton } from 'amazon-chime-sdk-component-library-react';
import React, { ReactNode, useContext } from 'react';
import { getFrontendURL } from '../utils/configuredURLs';
export class EventSession {
  brandId: string;
  userId: string;
  meetingId?: string;

  constructor(brandId: string, userId: string, meetingId: string = '') {
    if (brandId?.trim().length <= 0 || userId?.trim().length <= 0) {
      throw new Error('brandId must be provided to create eventSession');
    }
    this.brandId = brandId;
    this.userId = userId;
    this.meetingId = meetingId;
  }
}

type User = {
  id: string;
  name: string;
  meetingType: '1on1' | 'presentation';
};

type Props = {
  children: ReactNode;
};

const EventContext = React.createContext<EventSession>({
  brandId: '',
  userId: '',
  meetingId: '',
});

export default function EventProvider({ children }: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  let brandId = urlParams.get('brand')?.trim();
  let meetingId = urlParams.get('meeting')?.trim();
  const userId = urlParams.get('user')?.trim();

  const isDev = process.env.REACT_APP_DEV_FLAG;
  const frontendHost = getFrontendURL();

  const presentationMeetingId = process.env
    .REACT_APP_PRESENTATION_MEETING_ID as string;
  const presentationBrandId = process.env
    .REACT_APP_PRESENTATION_BRAND_ID as string;
  const oneOnOneMeetingId = process.env
    .REACT_APP_ONE_ON_ONE_MEETING_ID as string;

  const users: User[] = [
    {
      id: process.env.REACT_APP_MODERATOR_1_USER_ID as string,
      name: 'TN: Michael Werner',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_2_USER_ID as string,
      name: 'TN: Ingo Lorenz',
      meetingType: 'presentation',
    },
/*    {
      id: process.env.REACT_APP_MODERATOR_3_USER_ID as string,
      name: 'TN: Stephanie Beckers',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_4_USER_ID as string,
      name: 'TN: Florian Brödeker',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_5_USER_ID as string,
      name: 'Mod: Rene Beneke',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_6_USER_ID as string,
      name: 'Mod: Jan-Philipp Kelting',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_8_USER_ID as string,
      name: 'Mod: Extra',
      meetingType: 'presentation',
    },
    {
      id: process.env.REACT_APP_MODERATOR_9_USER_ID as string,
      name: 'Mod: Extra 2',
      meetingType: 'presentation',
    }, */
 /*  {
      id: process.env.REACT_APP_MODERATOR_6_USER_ID as string,
      name: 'J.P. Kelting 1on1',
      meetingType: '1on1',
    },
    {
      id: process.env.REACT_APP_MODERATOR_5_USER_ID as string,
      name: 'Rene Beneke 1on1',
      meetingType: '1on1',
    }, 
     {
      id: process.env.REACT_APP_MODERATOR_1_USER_ID as string,
      name: '1on1: Michael Werner',
      meetingType: '1on1',
    },
    {
      id: process.env.REACT_APP_MODERATOR_2_USER_ID as string,
      name: '1on1: Ingo Lorenz',
      meetingType: '1on1',
    },
    {
      id: process.env.REACT_APP_MODERATOR_3_USER_ID as string,
      name: '1on1: Stephanie Beckers',
      meetingType: '1on1',
    },
    {
      id: process.env.REACT_APP_MODERATOR_4_USER_ID as string,
      name: '1on1: Florian Brödeker',
      meetingType: '1on1',
    }, */
  ];

  const getURLWithSearchParams = (
    userId: string,
    brandId: string,
    meetingId: string
  ) => {
    return `${frontendHost}?brand=${brandId}&user=${userId}&meeting=${meetingId}#/`;
  };

  if (!brandId || !userId) {
    return (
      <>
        {isDev ? (
          <>
            {users.map((user) => (
              <PrimaryButton
                key={user.id}
                label={user.name}
                onClick={() => {
                  const meetingId =
                    user.meetingType === '1on1'
                      ? oneOnOneMeetingId
                      : presentationMeetingId;
                  window.open(
                    getURLWithSearchParams(
                      user.id,
                      presentationBrandId,
                      meetingId
                    )
                  );
                }}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  const eventSession = new EventSession(brandId, userId, meetingId);
  return (
    <EventContext.Provider value={eventSession}>
      {children}
    </EventContext.Provider>
  );
}

export function useEventContext() {
  const context = useContext(EventContext);

  if (context === undefined) {
    throw new Error(
      'useeventMessageApis must be used within a eventMessagesDispatchContext'
    );
  }

  return context;
}
